import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, Grid, Paper, Skeleton } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { httpAPI } from '../../AxiosApi.js';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from 'recharts';
import "./Style/commonStyles.scss";
import { ToastContainer, toast } from "react-toastify";

import instituteIcon from "../../assets/svgs/institute.svg";
import studentIcon from "../../assets/svgs/student.svg";
import scholarshipIcon from "../../assets/svgs/scholarship.svg";
import fundRequestIcon from "../../assets/svgs/fund-request.svg";
import patronIcon from "../../assets/svgs/patron.svg";
import walletIcon from "../../assets/svgs/wallet.svg";
import { useAuth } from "../Utils/AuthContext.js";

export default function Home() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const { logout } = useAuth();
    const navigate = useNavigate();

    const [totalWalletBalance, setTotalWalletBalance] = useState(0);


    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        try {
            const response = await httpAPI.post(
                `${process.env.REACT_APP_BACKEND_URL}/admin/adminWallet`,
            )
            if (response.status === 200) {
                const totalSentAmount = response.data.transactions.reduce(
                    (total, transaction) => total + transaction.platformFee,
                    0
                );

                setTotalWalletBalance(totalSentAmount)
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await httpAPI.get(`${process.env.REACT_APP_BACKEND_URL}/admin/stats`);
                if (response.status === 200) {
                    setData(response.data.response);
                    setLoading(false);
                }
            } catch (error) {
                if (error.response) {
                    if (error.response.status === 401) {
                        toast.error(error.response.data.message);
                        logout();
                        navigate("/");
                    } else {
                        //   toast.error("An error occurred:", error.response.data.message);
                        console.log(error)
                    }
                }
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);


    return (
        <Box >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: {
                        xs: 'flex-start',
                        sm: 'flex-start',
                        md: 'center',
                    },
                    flexDirection: {
                        xs: 'column',
                        sm: 'row'
                    },
                    padding: 2
                }}
            >
                <Typography variant="h4">
                    Hi, Welcome back 👋
                </Typography>
                {loading ? <Skeleton variant="text" width="20%"  animation="wave" />
                    :
                    <Typography variant="h6">
                        Wallet Amount : {totalWalletBalance?.toFixed(2)}
                    </Typography>
                }
            </Box>

            {loading ? (
                <SkeletonLoading />
            ) : (
                <Box sx={{ mb: '2rem', display: 'grid', gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr', lg: 'repeat(3, 1fr)', xl: 'repeat(3, 1fr)' }, rowGap: '2.5rem', columnGap: '1rem' }}>
                    <StatCard icon={studentIcon} title="Students" total={data?.totalStudents} active={data?.activeStudents} link="/dashboard/student" />
                    <StatCard icon={instituteIcon} title="Institutes" total={data?.totalInstitutes} active={data?.activeInstitutes} link="/dashboard/institute" />
                    <StatCard icon={patronIcon} title="Patrons" total={data?.totalPatrons} active={data?.activePatrons} link="/dashboard/patron" />
                    <StatCard icon={scholarshipIcon} title="Scholarships" total={data?.totalScholarship} active={data?.activeScholarship} link="/dashboard/scholarship" />
                    <StatCard icon={fundRequestIcon} title="Scholarship Fund Requests" total={data?.totalFundRequest} paid={data?.paidFundRequest} pending={data?.pendingFundRequest} link="/dashboard/fundRequest" />
                    <StatCard icon={walletIcon} title="Student Wallet Requests" total={data?.totalWalletRequest} paid={data?.paidWAlletRequest} pending={data?.pendingWalletRequest} link="/dashboard/walletRequests" />
                </Box>
            )}
            {!loading && (
                <Typography variant="h5" mt='2rem' mb='1rem' fontWeight='500'>Graphical Representation</Typography>
            )}
            {data && (
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={getChartData(data)}>
                        <CartesianGrid strokeDasharray="2 2" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="total" fill="#107a0a" />
                        <Bar dataKey="active" fill="#f4c808" />
                    </BarChart>
                </ResponsiveContainer>
            )}
            <ToastContainer />

        </Box>
    );
}

const StatCard = ({ icon, title, total, active, paid, pending, link }) => (
    <Box sx={{ height: '100%' }}>
        <Link to={link} style={{ height: '100%', textDecoration: 'none' }}>
            <Paper className="statCard" sx={{ borderRadius: '8px', border: '1px solid #efefef', padding: '10px 20px', height: '100%', maxHeight: { sm: '100%', md: '180px', lg: '180px', xl: '160px', boxShadow: " 0px 0px 23px 0px #DBDBDB" } }}>
                <Typography variant="h6" mb={2} sx={{ display: 'flex', alignItems: 'center', fontWeight: '600' }}>
                    <img src={icon} alt="icon" className="icon" />
                    <span style={{ marginLeft: '0.5rem' }}>{title}</span>
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                    <ValueDisplay label="Total" value={total} />
                    {active !== undefined && <ValueDisplay label="Active" value={active} />}
                    {paid !== undefined && <ValueDisplay label="Paid" value={paid} />}
                    {pending !== undefined && <ValueDisplay label="Pending" value={pending} />}
                </div>
            </Paper>
        </Link>
    </Box>
);

const ValueDisplay = ({ label, value }) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="subtitle1">{label}:</Typography>
        <Typography variant="subtitle1">{value}</Typography>
    </div>
);

const getChartData = (data) => {
    return [
        { name: 'Students', total: data?.totalStudents, active: data?.activeStudents },
        { name: 'Institutes', total: data?.totalInstitutes, active: data?.activeInstitutes },
        { name: 'Patrons', total: data?.totalPatrons, active: data?.activePatrons },
        { name: 'Scholarships', total: data?.totalScholarship, active: data?.activeScholarship },
        { name: 'Scholarship Fund Requests', total: data?.totalFundRequest, active: data?.activeFundRequest },
        { name: 'Student Wallet Requests', total: data?.totalWalletRequest, active: data?.activeWalletRequest }
    ];
};


const SkeletonLoading = () => (
    <Box sx={{ padding: { xs: '0', sm: '0', md: '0.5rem', lg: '1rem', xl: '1rem' } }}>
        <Grid container spacing={2}>
            {[...Array(6)].map((_, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} lg={4} xl={4}>
                    <Paper sx={{ borderRadius: '8px', border: '1px solid #efefef', padding: '10px 20px', boxShadow: "0px 0px 23px 0px #DBDBDB" }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                            <Skeleton variant="circular" width={40} height={40} />
                            <Skeleton width={120} height={24} animation="wave" />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                            <Skeleton variant="text" width="50%" animation="wave" />
                            <Skeleton variant="text" width="40%" animation="wave" />
                            <Skeleton variant="text" width="60%" animation="wave" />
                        </Box>
                    </Paper>
                </Grid>
            ))}
        </Grid>
        <Typography variant="h5" mt='2rem' mb='1rem' fontWeight='500'><Skeleton width={200} height={24} animation="wave" /></Typography>
        <Skeleton variant="rectangular" width="100%" height={300} animation="wave" />
    </Box>
);


