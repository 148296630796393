import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination,
  Skeleton,
  TextField,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import { httpAPI } from "../../AxiosApi.js";
import "../Admin/Style/Student.css";
import { MdVisibility } from "react-icons/md";
import moment from "moment";
import { useAuth } from "../Utils/AuthContext.js";

const Scholarship = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("title");
  const [order, setOrder] = useState("asc");
  const [role, setRole] = useState("all");
  const [globalFilter, setGlobalFilter] = useState("");
  const [currentTab, setCurrentTab] = useState(0);
  const [studentCounts, setStudentCounts] = useState(0);

  const fetchData = async () => {
    try {
      const response = await httpAPI.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/scholarshipDetails`
      );
      if (response.data && response.data.response) {
        setData(response.data.response);

        const studentScholarships = response.data.response.filter(
          (scholarship) => scholarship?.userId?.role === "student"
        );
        setStudentCounts(studentScholarships.length);
      } else {
        console.error("Invalid data structure in the response:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response) {
        if (error.response.status === 401) {
          toast.error(error.response.data.message);
          logout();
          navigate("/");
        } else {
          toast.error("An error occurred:", error.message);
        }
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleTabChange = (newValue) => {
    console.log(newValue, "change tab");
    setRole(newValue);
    setCurrentTab(newValue);
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const filterByValue = (object, searchValue) => {
    return Object.values(object).some((value) => {
      if (typeof value === "object" && value !== null) {
        return filterByValue(value, searchValue);
      }
      return value?.toString()?.toLowerCase()?.includes(searchValue?.toLowerCase());
    });
  };

  const filteredData = data.filter((request) =>
    filterByValue(request, globalFilter)
  );

  const sortedData = stableSort(filteredData, getComparator(order, orderBy))
    .filter((scholarship) => {
      if (role === "student") {
        return scholarship?.userId?.role === "student";
      } else if (role === "institute") {
        return scholarship?.userId?.role === "institute";
      }
      return true; // Return true for other roles or when no role is selected
    })
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const scholarshipStudentsCounts = sortedData.filter(
    (request) => request?.userId?.role === "student"
  ).length;
  const scholarshipInstituteCounts = sortedData.filter(
    (request) => request?.userId?.role === "institute"
  ).length;

  console.log(
    sortedData.filter(
      (request) => request.scholarship?.userId?.role === "student"
    )
  );

  return (
    <div className="student">
      <div className="btnHeader">
        <h1 style={{ textAlign: "left" }}>Scholarship Listing</h1>
      </div>

      <Box
        sx={{
          // borderBottom: 1,
          borderColor: "divider",
          // mb: "0.5rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "10px 15px",
        }}
      >
        {/* <Tabs
          value={role}
          onChange={(e, newValue) => setRole(newValue)}
          indicatorColor="primary"
          sx={{
            background: "#eee",
            padding: "5px",
            borderRadius: "5px",
          }}
        >
          <Tab
            label="Student"
            value="student"
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#107a0a",
                color: "#fff",
                borderRadius: "5px",
              },
            }}
          />
          <Tab
            label="Institute"
            value="institute"
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#107a0a",
                color: "#fff",
                borderRadius: "5px",
              },
            }}
          />
        </Tabs> */}

        {/* New tab with numbers */}
        <Tabs
          value={role}
          onChange={(e, newValue) => setRole(newValue)}
          variant="fullWidth"
          aria-label="Active and Inactive Tabs"
          sx={{
            background: "#fff",
            padding: "5px",
            borderRadius: "5px",
            width: { xs: "100%", sm: "100%", md: "100%", lg: "50%", xl: "50%" },
          }}
        >
          <Tab
            label={
              <Box
                sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                <span style={{ paddingRight: "10px", fontWeight: "bold" }}>
                  All{" "}
                </span>
                <span
                  style={{
                    backgroundColor: "#212B36",
                    color: "#fff",
                    padding: "5px 10px",
                    borderRadius: "5px",
                  }}
                >
                  {data.length}
                </span>
              </Box>
            }
            value="all"
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#eaeaea",
                color: "#000",
                borderRadius: "5px",
              },
            }}
          />
          <Tab
            label={
              <Box
                sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                <span style={{ paddingRight: "10px", fontWeight: "bold" }}>
                  Student{" "}
                </span>
                <span
                  style={{
                    backgroundColor: "#22C55E29",
                    color: "#118D57",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    fontWeight: 900,
                  }}
                >
                  {studentCounts}
                </span>
              </Box>
            }
            // label={`Active (${activeStudentsCount})`}
            value="student"
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#eaeaea",
                color: "#000",
                borderRadius: "5px",
              },
            }}
          />
          <Tab
            label={
              <Box
                sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                <span style={{ paddingRight: "10px", fontWeight: "bold" }}>
                  Institute{" "}
                </span>
                <span
                  style={{
                    backgroundColor: "#FF563029",
                    color: "#B71D18",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    fontWeight: 900,
                  }}
                >
                  {scholarshipInstituteCounts}
                </span>
              </Box>
            }
            // label={`Inactive (${inactiveStudentsCount})`}
            value="institute"
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#eaeaea",
                color: "#000",
                borderRadius: "5px",
              },
            }}
          />
        </Tabs>

        <Box
          className="search"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <TextField
            value={globalFilter}
            type="search"
            onChange={(e) => setGlobalFilter(e?.target?.value)}
            label="Search"
            // sx={{ width:'100%' }}
          />
        </Box>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "title"}
                  direction={orderBy === "title" ? order : "asc"}
                  onClick={() => handleSort("title")}
                >
                  Title
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "description"}
                  direction={orderBy === "description" ? order : "asc"}
                  onClick={() => handleSort("description")}
                >
                  Description
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "purpose"}
                  direction={orderBy === "purpose" ? order : "asc"}
                  onClick={() => handleSort("purpose")}
                >
                  Purpose
                </TableSortLabel>
              </TableCell>
              {role === "student" ? (
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "courseName"}
                    direction={orderBy === "courseName" ? order : "asc"}
                    onClick={() => handleSort("courseName")}
                  >
                    Course Name
                  </TableSortLabel>
                </TableCell>
              ) : (
                ""
              )}

              <TableCell>
                <TableSortLabel
                  active={orderBy === "targetDate"}
                  direction={orderBy === "targetDate" ? order : "asc"}
                  onClick={() => handleSort("targetDate")}
                >
                  Target Date
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "raisedAmount"}
                  direction={orderBy === "raisedAmount" ? order : "asc"}
                  onClick={() => handleSort("raisedAmount")}
                >
                  Raised Amount
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "targetAmount"}
                  direction={orderBy === "targetAmount" ? order : "asc"}
                  onClick={() => handleSort("targetAmount")}
                >
                  Target Amount
                </TableSortLabel>
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              Array.from({ length: 8 }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} />
                  </TableCell>
                  {/* <TableCell>
                    <Skeleton animation="wave" width={"100%"} />
                  </TableCell> */}
                </TableRow>
              ))
            ) : sortedData.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={9}
                  align="center"
                  sx={{ textAlign: "center !important" }}
                >
                  No data found
                </TableCell>
              </TableRow>
            ) : (
              // Render filtered scholarship data
              sortedData.map((row) => (
                <TableRow key={row._id}>
                  <TableCell>
                    {row.title?.length > 20
                      ? row.title?.substring(0, 20) + `...`
                      : row.title}
                  </TableCell>
                  <TableCell>
                    {row.description?.length > 20
                      ? row.description?.substring(0, 30) + `...`
                      : row.description}
                  </TableCell>
                  <TableCell>{row.purpose}</TableCell>
                  {role === "student" ? (
                    <TableCell>
                      {row.courseName?.length > 20
                        ? row.courseName.substring(0, 20) + `...`
                        : row.courseName}
                    </TableCell>
                  ) : (
                    ""
                  )}

                  <TableCell>
                    {row.targetDate &&
                      moment(row.targetDate).format("MMM DD, YYYY")}
                  </TableCell>

                  <TableCell>
                    {row.raisedAmount !== "NA" ? "$" + row.raisedAmount : "NA"}
                  </TableCell>
                  <TableCell>${row.targetAmount}</TableCell>

                  <TableCell>
                    <NavLink to={`/dashboard/viewScholarship/${row._id}`}>
                      <MdVisibility
                        style={{ fontSize: "20px", color: "#000" }}
                      />
                    </NavLink>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ToastContainer />
    </div>
  );
};

export default Scholarship;
