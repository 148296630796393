
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar.js'
import { Box, Drawer } from '@mui/material';
import Topbar from './Topbar.js';

import './Style/commonStyles.scss'

const drawerWidth = 260;

const Dashboard = ({ window }) => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex', boxSizing: 'border-box' }}>
            <Box sx={{ display: { xs:'block', sm: 'block', md: 'none' } }}>
                <Topbar handleDrawerToggle={handleDrawerToggle} />
            </Box>
            <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    <Sidebar />
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'none', md: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    <Sidebar/>
                </Drawer>
            </Box>
            <Box component="main" sx={{ boxSizing: 'border-box', flexGrow: 1, p: 0, width: { xs: '100%',sm: `calc(100% - ${drawerWidth}px)` }, mt: { xs: '4rem', sm: '4rem', md:'0rem' }, padding: '1.5rem' }}>
                <Box sx={{ mt: '0rem', boxSizing: 'border-box' }}>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
};

export default Dashboard;
