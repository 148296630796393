import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Tab,
  Tabs,
  Button,
  CircularProgress,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { httpAPI } from "../../AxiosApi.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { TbSquareRoundedArrowLeftFilled } from "react-icons/tb";

const EditInstituteForm = ({ data, onClose }) => {
  const [editedData, setEditedData] = useState({});
  const [currentTab, setCurrentTab] = useState(0);
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setEditedData({ ...data });
  }, [data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if the property is nested
    const [parentKey, childKey] = name.split(".");
    if (childKey) {
      // Update nested property
      setEditedData((prevData) => ({
        ...prevData,
        [parentKey]: {
          ...prevData[parentKey],
          [childKey]: value,
        },
      }));
    } else {
      // Update top-level property
      setEditedData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    fetchStudentDetails();
  }, [id]);

  const fetchStudentDetails = async () => {
    try {
      setIsSendingRequest(true);
      const response = await httpAPI.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getInstitute/${id}`
      );
      if (response.status === 200) {
        setEditedData(response.data.response);
      } else {
        console.error("Failed to fetch student details");
      }
    } catch (error) {
      console.error("Error fetching student details:", error);
    } finally {
      setIsSendingRequest(false);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSendingRequest(true);
      const response = await httpAPI.put(
        `${process.env.REACT_APP_BACKEND_URL}/admin/updateInstitute/${id}`,
        editedData
      );
      if (response.status === 200) {
        toast.success("Institute updated successfully");
        fetchStudentDetails();
      } else {
        console.error("Failed to update institute");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    } finally {
      setIsSendingRequest(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleGoBack = () => {
    navigate("/dashboard/institute");
  };

  return (
    <Box className="editInstitute">
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          width: "100%",
          padding: "1rem",
          background: "#eeeeee",
          borderRadius: "5px",
          marginBottom: "2rem",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start", gap: "1rem" }}>
          <span style={{ cursor: "pointer" }} onClick={handleGoBack}>
            <TbSquareRoundedArrowLeftFilled
              style={{ fontSize: "30px", color: "#212529" }}
            />
          </span>
          <h2 style={{ margin: "0" }}>Edit Institute</h2>
        </Box>
      </Box>

      <form onSubmit={handleFormSubmit}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          sx={{
            background: "#eee",
            padding: "5px",
            borderRadius: "5px",
            width: "fit-content",
            mb: "1rem",
          }}
        >
          <Tab
            label="Institute Info"
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#107a0a",
                color: "#fff",
                borderRadius: "5px",
              },
            }}
          />
          <Tab
            label="Institute Address"
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#107a0a",
                color: "#fff",
                borderRadius: "5px",
              },
            }}
          />
          <Tab
            label="Bank Details"
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#107a0a",
                color: "#fff",
                borderRadius: "5px",
              },
            }}
          />
        </Tabs>
        <Box>
          {currentTab === 0 && (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
                flexDirection: "column",
                gap: "1rem",
                width: "100%",
              }}
            >
              <TextField
                fullWidth
                type="text"
                name="fullName"
                label="Full Name"
                value={editedData.fullName || ""}
                onChange={handleInputChange}
              />
              <TextField
                fullWidth
                type="date"
                name="dateOfEstablishment"
                label="Date of Establishment"
                value={
                  editedData.dateOfEstablishment
                    ? editedData.dateOfEstablishment.substring(0, 10)
                    : ""
                }
                onChange={handleInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          )}
          {currentTab === 1 && (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
                flexDirection: "column",
                gap: "1rem",
                width: "100%",
              }}
            >
              <TextField
                fullWidth
                type="text"
                name="address.street"
                label="Street"
                value={editedData.address ? editedData.address.street : ""}
                onChange={handleInputChange}
              />
              <TextField
                fullWidth
                type="text"
                name="address.city"
                label="City"
                value={editedData.address ? editedData.address.city : ""}
                onChange={handleInputChange}
              />
              <TextField
                fullWidth
                type="text"
                name="address.state"
                label="State"
                value={editedData.address ? editedData.address.state : ""}
                onChange={handleInputChange}
              />
              <TextField
                fullWidth
                type="text"
                name="address.pincode"
                label="Pincode"
                value={editedData.address ? editedData.address.pincode : ""}
                onChange={handleInputChange}
              />
              <TextField
                fullWidth
                type="text"
                name="address.country"
                label="Country"
                value={editedData.address ? editedData.address.country : ""}
                onChange={handleInputChange}
              />
            </Box>
          )}
          {currentTab === 2 && (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
                flexDirection: "column",
                gap: "1rem",
                width: "100%",
              }}
            >
              <TextField
                fullWidth
                type="text"
                name="bankDetails.bankName"
                label="Bank Name"
                value={
                  editedData.bankDetails ? editedData.bankDetails.bankName : ""
                }
                onChange={handleInputChange}
              />
              <TextField
                fullWidth
                type="text"
                name="bankDetails.accountNumber"
                label="Account Number"
                value={
                  editedData.bankDetails
                    ? editedData.bankDetails.accountNumber
                    : ""
                }
                onChange={handleInputChange}
              />

              <TextField
                fullWidth
                type="text"
                name="bankDetails.bankCode"
                label="Routing Number"
                value={
                  editedData.bankDetails ? editedData.bankDetails.bankCode : ""
                }
                onChange={handleInputChange}
              />

              <TextField
                fullWidth
                type="text"
                name="bankDetails.ownerName"
                label="Account Holder Name"
                value={
                  editedData.bankDetails ? editedData.bankDetails.ownerName : ""
                }
                onChange={handleInputChange}
              />

            </Box>
          )}
        </Box>
        {isSendingRequest ? 
        <Box sx={{ overflow:'hidden', position:'absolute', height:'70px', width: '100px', top:'50%', left:'50%', transform:'translate(-50%, -50%)', display:'flex', justifyContent:'center', alignItems:'100%', background:'rgba(0,0,0,0.5)', padding:'1rem'  }}>
          <Box sx={{ overflow:'hidden', display:'flex', justifyContent:'center', alignItems:'center' }}>
          <CircularProgress size={24} sx={{ color: '#fff' }} />
          </Box>
        </Box> 
         : 
          <Box className="editBtn">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleFormSubmit}
              sx={{marginTop:"20px"}}
            >
SAVE CHANGES
            </Button>
          </Box>
       } 
      </form>
      <ToastContainer />
    </Box>
  );
};

export default EditInstituteForm;
