import { ThemeProvider, createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: '#107a0a', // Green color
        },
    },
    typography: {
        fontFamily: ['Poppins', 'Arial', 'sans-serif'].join(','),
    },
});

export default function CustomThemeProvider({ children }) {
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
}
