import React, { useEffect, useState } from "react";
import { httpAPI } from "../../AxiosApi.js";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
  TableSortLabel,
  Tabs,
  Tab,
  Box,
  Skeleton,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { MdFileUpload, MdVisibility } from "react-icons/md";
import moment from 'moment';
import { useAuth } from "../Utils/AuthContext.js";
import { ToastContainer, toast } from "react-toastify";


export default function WalletRequest() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState("");
  const [page, setPage] = useState(0);
  const [value, setValue] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("userID.fullName");
  const [order, setOrder] = useState("asc");
  const [activeTab, setActiveTab] = useState("all");
  const [isStatusChanging, setStatusChanging] = useState(false);
  const { logout } = useAuth();

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await httpAPI.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/walletRequest`
      );
      if (response.data && response.data.response) {
        setData(response.data.response);
      } else {
        console.error("Invalid data structure in the response:", response.data);

      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response) {
        if (error.response.status === 401) {
          toast.error(error.response.data.message);
          logout();
          navigate("/");
        } else { 
          toast.error("An error occurred:", error.message);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const filterByValue = (object, searchValue) => {
    return Object.values(object).some((value) => {
      if (typeof value === 'object' && value !== null) {
        return filterByValue(value, searchValue);
      }
      return value?.toString()?.toLowerCase()?.includes(searchValue?.toLowerCase());
    });
  };

  const filteredData = data.filter((request) => filterByValue(request, globalFilter));


  const sortedData = stableSort(filteredData, getComparator(order, orderBy));



  let displayedData;
  if (activeTab === "pending") {
    displayedData = sortedData.filter((request) => request.status === "pending");
  } else if (activeTab === "paid") {
    displayedData = sortedData.filter((request) => request.status === "paid")
  } else {
    displayedData = sortedData;
  }

  const pendingRequestsCount = sortedData.filter((request) => request.status === "pending").length;
  const paidRequestsCount = sortedData.filter((request) => request.status === "paid").length;

  return (
    <div className="student">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isStatusChanging}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="btnHeader">
        <h1 style={{ textAlign: 'left' }}>Student Wallet Requests</h1>
      </div>
      <Box
        sx={{
          // borderBottom: 1,
          borderColor: "divider",
          mb: "0.5rem",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
        }}
      >
        {/* <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          variant="fullWidth"
          aria-label="Active and Inactive Tabs"
          sx={{ background: "#eee", padding: "5px", borderRadius: "5px" }}
        >
          <Tab
            label="Pending"
            value="pending"
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#107a0a",
                color: "#fff",
                borderRadius: "5px",
              },
            }}
          />
          <Tab
            label="Paid"
            value="paid"
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#107a0a",
                color: "#fff",
                borderRadius: "5px",
              },
            }}
          />
        </Tabs> */}


        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          variant="fullWidth"
          aria-label="Active and Inactive Tabs"
          sx={{ background: "#fff", padding: "5px", borderRadius: "0px", width: { xs: '100%', sm: '100%', md: '100%', lg: '50%', xl: '50%' } }}
        >
          <Tab
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                <span style={{ paddingRight: '10px', fontWeight: "bold" }}>

                  All{" "}
                </span>
                <span style={{ backgroundColor: "#212B36", color: "#fff", padding: "5px 10px", borderRadius: "5px" }}>
                  {data.length}
                </span>
              </Box>
            }
            value="all"
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#eaeaea",
                color: "#000",
                borderRadius: "5px",
              },
            }}
          />
          <Tab
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                <span style={{ paddingRight: '10px', fontWeight: "bold" }}>

                  Paid{" "}
                </span>
                <span style={{ backgroundColor: "#22C55E29", color: "#118D57", padding: "5px 10px", borderRadius: "5px", fontWeight: 900 }}>
                  {paidRequestsCount}
                </span>
              </Box>
            }
            // label={`Active (${activeStudentsCount})`}
            value="paid"
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#eaeaea",
                color: "#000",
                borderRadius: "5px",
              },
            }}
          />
          <Tab
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                <span style={{ paddingRight: '10px', fontWeight: "bold" }}>
                  Pending{" "}
                </span>
                <span style={{ backgroundColor: "#FF563029", color: "#B71D18", padding: "5px 10px", borderRadius: "5px", fontWeight: 900 }}>
                  {pendingRequestsCount}
                </span>
              </Box>
            }
            // label={`Inactive (${inactiveStudentsCount})`}
            value="pending"
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#eaeaea",
                color: "#000",
                borderRadius: "5px",
              },
            }}
          />

        </Tabs>
        <Box className="search" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <TextField
            value={globalFilter}
            type="search"
            onChange={(e) => setGlobalFilter(e.target.value)}
            label="Search"
          />
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow >
              <TableCell>Image</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "userID.fullName"}
                  direction={orderBy === "userID.fullName" ? order : "asc"}
                  onClick={() => handleRequestSort("userID.fullName")}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "userID.email"}
                  direction={orderBy === "userID.email" ? order : "asc"}
                  onClick={() => handleRequestSort("userID.email")}
                >
                  Email
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "userID.phone.phoneNumber"}
                  direction={orderBy === "userID.phone.phoneNumber" ? order : "asc"}
                  onClick={() => handleRequestSort("userID.phone.phoneNumber")}
                >
                  Phone Number
                </TableSortLabel>
              </TableCell>
              {/* <TableCell>
                <TableSortLabel
                  active={orderBy === "userID.role"}
                  direction={orderBy === "userID.role" ? order : "asc"}
                  onClick={() => handleRequestSort("userID.role")}
                >
                  Role
                </TableSortLabel>
              </TableCell> */}
              <TableCell>
                <TableSortLabel
                  active={orderBy === "walletID.createdAt"}
                  direction={orderBy === "walletID.createdAt" ? order : "asc"}
                  onClick={() => handleRequestSort("walletID.createdAt")}
                >
                  {activeTab === "pending"
                    ? "Date"
                    : "Approved Date"}
                </TableSortLabel>
              </TableCell>
              {activeTab == "pending" ? <>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "walletID.walletBalance"}
                    direction={orderBy === "walletID.walletBalance" ? order : "asc"}
                    onClick={() => handleRequestSort("walletID.walletBalance")}
                  >
                    Wallet Amount
                  </TableSortLabel>
                </TableCell>
              </> : ""}
              <TableCell>
                <TableSortLabel
                  active={orderBy === "amount"}
                  direction={orderBy === "amount" ? order : "asc"}
                  onClick={() => handleRequestSort("amount")}
                >
                  Requested Amount
                </TableSortLabel>
              </TableCell>

              {activeTab === "pending" ? (
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "amount"}
                    direction={orderBy === "amount" ? order : "asc"}
                    onClick={() => handleRequestSort("amount")}
                  >
                    Payable Amount
                  </TableSortLabel>
                </TableCell>
              ) :(
              
              <TableCell>
                <TableSortLabel
                  active={orderBy === "paidAmount"}
                  direction={orderBy === "paidAmount" ? order : "asc"}
                  onClick={() => handleRequestSort("paidAmount")}
                >
                  Paid Amount
                </TableSortLabel>
              </TableCell>)}

                
              <TableCell>
                <TableSortLabel
                  active={orderBy === "platformFee"}
                  direction={orderBy === "platformFee" ? order : "asc"}
                  onClick={() => handleRequestSort("platformFee")}
                >
                  Platform Fee
                </TableSortLabel>
              </TableCell>

              
              {activeTab === "paid" && (
                <TableCell>
                  Transaction ID
                </TableCell>
              )}
              <TableCell>Actions</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              Array.from({ length: 8 }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} />
                  </TableCell>
                </TableRow>
              ))
            ) : displayedData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={10} align="center" sx={{ textAlign: 'center !important' }}>
                  No data found
                </TableCell>
              </TableRow>
            ) : (
              displayedData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>
                      <img
                        src={row.userID.profilePicture && !row.userID.profilePicture.endsWith('/null')
                          ? `${process.env.REACT_APP_BACKEND_URL}/uploads/${row.userID.profilePicture}`
                          : "/images/dummyImage.png"}
                        alt="Student-img"
                        // style={{ width: 30 }}
                        style={{ borderRadius: '50%', width: 40, height: 40 }}
                      // onError={(e) => (e.target.src = "dummy-image-url")}
                      />
                    </TableCell>
                    <TableCell>
                      {row?.userID?.fullName.substring(0, 20)}
                    </TableCell>
                    <TableCell>{row?.userID?.email.substring(0, 20)}</TableCell>
                    <TableCell>{`${row.userID?.phone.countryCode}-${row?.userID?.phone.phoneNumber}`}</TableCell>
                    {/* <TableCell>{row?.userID?.role}</TableCell> */}
                    <TableCell>{row?.createdAt && moment(row?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</TableCell>

                    {activeTab == "pending" ? <TableCell>${row?.walletID?.walletBalance}</TableCell> : ""}
                    <TableCell>${row?.amount}</TableCell>
                    <TableCell>${(row?.paidAmount)?.toFixed(1)}</TableCell>
                    <TableCell 
                    // sx={{ color: "green" }}
                    >
                       ${row?.platformFee.toFixed(2)}
                    </TableCell>

                    {activeTab === "paid" && (
                      <TableCell>
                        {row?.receipt?.transactionId || 'NA'}
                      </TableCell>
                    )}
                    <TableCell>
                      <Box className="btn-grp">
                        <NavLink to={`/dashboard/viewWalletRequest/${row?.userID?._id}`}>
                          <MdVisibility
                            style={{ fontSize: "20px", color: "#000" }}
                          />
                        </NavLink>
                        {activeTab === "pending" && (
                          <NavLink
                            to={`/dashboard/walletReceiptUpload/${row?.userID?._id}/search?walletID=${row?.walletID?._id}&&amount=${row?.amount}&&email=${row?.userID?.email}&&walletRequest=${row?._id}`}
                          >
                            <MdFileUpload
                              style={{
                                fontSize: "20px",
                                color: "#000",
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                            />
                          </NavLink>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && data && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={displayedData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <ToastContainer />
    </div>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
