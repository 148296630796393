import React, { useState, useEffect } from "react";
import {
  Box, Typography, Tab, Tabs, Divider, Skeleton, Modal,
  Backdrop,
  IconButton,
  TableRow, TableCell,
  FormControlLabel, Switch, Dialog, DialogTitle, DialogContent, DialogActions, Button
} from "@mui/material";
import { TbSquareRoundedArrowLeftFilled } from "react-icons/tb";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { httpAPI } from "../../../AxiosApi.js";
import { CleanHands } from "@mui/icons-material";
import { MdCancel } from "react-icons/md";
import moment from 'moment';
import { ToastContainer, toast } from "react-toastify";
import { AiFillEdit } from "react-icons/ai";


const InstituteDetailPopup = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get the ID from the URL
  const [student, setStudent] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxImage, setLightboxImage] = useState(null);
  const [selectedInstituteId, setSelectedInstituteId] = useState(null);
  const [statusChangeConfirmationOpen, setStatusChangeConfirmationOpen] = useState(false);
  const [statusChangeActive, setStatusChangeActive] = useState(true);
  const [scholarshipData, setScholarshipData] = useState(null);
  const [isStatusChanging, setStatusChanging] = useState(false);


  const toggleStatus = async (userId, isActive) => {
    console.log(userId, isActive)
    setStatusChangeConfirmationOpen(true);
    setStatusChangeActive(!isActive);
    setSelectedInstituteId(userId);
  };




  const fetchStudentDetails = async () => {
    try {
      const response = await httpAPI.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getInstitute/${id}`
      );
      console.log(response, "-=-=-=-=-=-=-=-=-=-=-=-=-=-=");
      if (response.status === 200) {
        setStudent(response.data.response);
      } else {
        console.error("Failed to fetch student details");
      }
    } catch (error) {
      console.error("Error fetching student details:", error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {

    fetchStudentDetails();
  }, [id]);



  const fetchScholarShips = async () => {
    try {
      const response = await httpAPI.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/scholarship?userId=${id}`
      );
      if (response.status === 200) {
        setScholarshipData(response.data.response);
      } else {
        console.error("Failed to fetch student details");
      }
    } catch (error) {
      console.error("Error fetching student details:", error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchScholarShips();
  }, [id]);



  const confirmStatusChange = async () => {
    setStatusChanging(true);
    try {
      await httpAPI.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/checkIsActive/${selectedInstituteId}`
      );
      fetchStudentDetails();
      if (statusChangeActive) {
        toast.success("Status changed to inactive.");
      } else {
        toast.success("Status changed to active.");
      }
    } catch (error) {
      console.error("Error toggling isActive:", error);
    } finally {
      setStatusChanging(false);
      setStatusChangeConfirmationOpen(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleGoBack = () => {
    navigate("/dashboard/institute");
  };

  const openLightbox = (imageUrl) => {
    setLightboxImage(imageUrl);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };


  const profilePicture = (student?.profilePicture && !student?.profilePicture?.endsWith('/null'))
    ? `${process.env.REACT_APP_BACKEND_URL}/uploads/${student.profilePicture}`
    : "/images/dummyImage.png";



  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: {
          xs: '0',
          sm: '1rem',
          md: '1rem',
        },
        borderRadius: "10px",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          width: '100%',
          padding: "1rem",
          background: "#eeeeee",
          borderRadius: "5px",
          marginBottom: "2rem",
          boxSizing: "border-box",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start", gap: "1rem" }}>
          <span style={{ cursor: "pointer" }} onClick={handleGoBack}>
            <TbSquareRoundedArrowLeftFilled
              style={{ fontSize: "30px", color: "#212529" }}
            />
          </span>
          <Typography variant="h5" style={{ margin: "0" }}>
            Institute Details
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box>

            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              value={currentTab}
              onChange={handleTabChange}
              textColor="primary"
              indicatorColor="primary"
              sx={{
                background: "#eee",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              <Tab
                label="Personal Details"
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#107a0a",
                    color: "#fff",
                    borderRadius: "5px",
                  },
                }}
              />
              <Tab
                label="Scholarship Details"
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#107a0a",
                    color: "#fff",
                    borderRadius: "5px",
                  },
                }}
              />
            </Tabs>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>

            <NavLink to={`/dashboard/editInstitute/${student?._id}`} style={{ padding: '2rem' }}>
              <Button variant="contained">
                Edit
                <AiFillEdit
                  style={{ fontSize: "20px" }}
                />
              </Button>
            </NavLink>
            <Box sx={{
              // display: "flex",
              // justifyContent: "flex-start",
              // flexDirection: 'column',
              // gap: "5px",
              // backgroundColor: '#f6f6f6',
              // padding: '5px 10px'
            }}>

              <FormControlLabel
                control={
                  <Switch
                    checked={student?.isActive}
                    onChange={() => toggleStatus(student?._id, student?.isActive)}
                  />
                }
              />
            </Box>

          </Box>

        </Box>



      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: "100%",
          padding: "1rem",
          background: "#ffffff",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          boxSizing: "border-box",
        }}
      >
        {loading || !student ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={200} height={30} />
            </Box>
            <Box 
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={200} height={30} />
            </Box>

            <Divider />

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={200} height={30} />
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={200} height={30} />
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={200} height={30} />
            </Box>
          </>
        ) : (
          <>
            {currentTab === 0 && (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', justifyContent: "space-between", marginBottom: '1rem', gridTemplateColumns: { xs: '1fr', sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr', xl: '1fr 1fr 1fr' }, gap: '1rem' }}>
                  <Box sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: 'column',
                    gap: "5px",
                    // backgroundColor:'#f6f6f6',
                    padding: '5px 10px'
                  }}>

                    <img
                      src={profilePicture}
                      style={{ width: '150px', cursor: 'pointer' }}
                      onClick={() => openLightbox(profilePicture)}
                      alt="Profile"
                    />
                    <Box >
                  </Box>
                  </Box>
                </Box>
                <Dialog
                  open={statusChangeConfirmationOpen}
                  onClose={() => setStatusChangeConfirmationOpen(false)}
                >
                  <DialogTitle>Confirmation</DialogTitle>
                  <DialogContent>
                    Are you sure you want to change the status of this institute?
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setStatusChangeConfirmationOpen(false)}>
                      Cancel
                    </Button>
                    <Button onClick={confirmStatusChange}>Change Status</Button>
                  </DialogActions>
                </Dialog>
                <Box sx={{ display: 'grid', gridTemplateColumns: { xs: '1fr', sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr', xl: '1fr 1fr 1fr' }, gap: '1rem' }}>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: 'column',
                      gap: "5px",
                      backgroundColor: '#f6f6f6',
                      padding: '5px 10px'
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Name:
                    </Typography>
                    <Typography variant="body1">{student.fullName}</Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: 'column',
                      gap: "5px",
                      backgroundColor: '#f6f6f6',
                      padding: '5px 10px'
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Email:
                    </Typography>
                    <Typography variant="body1">{student.email}</Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: 'column',
                      gap: "5px",
                      backgroundColor: '#f6f6f6',
                      padding: '5px 10px'
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Date of Establishment:
                    </Typography>
                    <Typography variant="body1">
                      {student.dateOfEstablishment && moment(student.dateOfEstablishment).format('MMM DD, YYYY')}

                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: 'column',
                      gap: "5px",
                      backgroundColor: '#f6f6f6',
                      padding: '5px 10px'
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Phone:
                    </Typography>
                    <Typography variant="body1">{`${student.phone?.countryCode} - ${student.phone?.phoneNumber}`}</Typography>
                  </Box>



                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "0.5rem",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "600",
                      fontSize: "22px !important",
                      textDecorationLine: ' underline',
                      marginBottom: '0.3rem',
                      marginTop: '1rem'
                    }}
                  >
                    Address
                  </Typography>
                  <Box
                    sx={{ display: 'grid', gridTemplateColumns: { xs: '1fr', sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr', xl: '1fr 1fr 1fr' }, gap: '1rem' }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: 'column',
                        gap: "5px",
                        backgroundColor: '#f6f6f6',
                        padding: '5px 10px'
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Street:
                      </Typography>
                      <Typography variant="body1">
                        {student.address.street}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: 'column',
                        gap: "5px",
                        backgroundColor: '#f6f6f6',
                        padding: '5px 10px'
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        City:
                      </Typography>
                      <Typography variant="body1">
                        {student.address?.city}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: 'column',
                        gap: "5px",
                        backgroundColor: '#f6f6f6',
                        padding: '5px 10px'
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        State:
                      </Typography>
                      <Typography variant="body1">
                        {student.address?.state}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: 'column',
                        gap: "5px",
                        backgroundColor: '#f6f6f6',
                        padding: '5px 10px'
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Pincode:
                      </Typography>
                      <Typography variant="body1">
                        {student.address?.pincode}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: 'column',
                        gap: "5px",
                        backgroundColor: '#f6f6f6',
                        padding: '5px 10px'
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Country:
                      </Typography>
                      <Typography variant="body1">
                        {student.address?.country}
                      </Typography>
                    </Box>
                  </Box>
                </Box>



                {/* bank details */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "0.5rem",
                    flexDirection: "column",
                  }}
                >


                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "600",
                      fontSize: "22px !important",
                      textDecorationLine: ' underline',
                      marginBottom: '0.3rem',
                      marginTop: '1rem'
                    }}
                  >

                    Bank Details
                  </Typography>
                  <Box
                    sx={{ display: 'grid', gridTemplateColumns: { xs: '1fr', sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr', xl: '1fr 1fr 1fr' }, gap: '1rem' }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: 'column',
                        gap: "5px",
                        backgroundColor: '#f6f6f6',
                        padding: '5px 10px'
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Bank Name:
                      </Typography>
                      <Typography variant="body1">
                        {student?.bankDetails?.bankName}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: 'column',
                        gap: "5px",
                        backgroundColor: '#f6f6f6',
                        padding: '5px 10px'
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Account Holder Name:
                      </Typography>
                      <Typography variant="body1">
                        {student?.bankDetails?.ownerName}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: 'column',
                        gap: "5px",
                        backgroundColor: '#f6f6f6',
                        padding: '5px 10px'
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Account Number:
                      </Typography>
                      <Typography variant="body1">
                        {student?.bankDetails?.accountNumber}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: 'column',
                        gap: "5px",
                        backgroundColor: '#f6f6f6',
                        padding: '5px 10px'
                      }}
                    >

                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Routing Number:
                      </Typography>
                      <Typography variant="body1">
                        {student?.bankDetails?.bankCode}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>


            )}
            {currentTab === 1 && (
              <Box sx={{ display: 'grid', gridTemplateColumns: { xs: '1fr', sm: '1fr', md: '1fr 1fr', lg: 'repeat(3, 1fr)', xl: 'repeat(3, 1fr)', gap: '1rem', alignItems: 'flex-start' } }}>
                {
                  (scholarshipData && scholarshipData.length === 0) ? (
                    <TableRow>
                      <TableCell colSpan={4} align="center" sx={{ textAlign: 'center !important' }}>
                        No data found
                      </TableCell>
                    </TableRow>
                  ) : (

                    scholarshipData?.map((data, index) => {
                      return (
                        <Box key={index} sx={{
                          display: 'flex', flexDirection: 'column', gap: '1rem', background: '#f6f6f6', borderRadius: '5px', padding: '1rem 1.5rem', border: '1px solid #cfcfcf'

                        }}>
                          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '0.5rem' }}>

                            <Box >
                              <Typography sx={{ fontWeight: '600' }}>Title</Typography>
                              <Typography>{data.title}</Typography>
                            </Box>

                            {/* <Box>
                           <Typography sx={{ fontWeight: '600' }}>Course Name:</Typography>
                           <Typography>{data.courseName}</Typography>
  
                         </Box> */}
                            <Box>
                              <Typography sx={{ fontWeight: '600' }}>Description</Typography>
                              <Typography>
                                {data.description.length > 20
                                  ? data.description.substring(0, 20) + "..."
                                  : data.description}
                              </Typography>
                            </Box>
                            {/* <Box >
                           <Typography sx={{ fontWeight: '600' }}>Active</Typography>
                           <Typography>{data.isActive}</Typography>
                         </Box> */}
                            {/* <Box>
       <Typography sx={{ fontWeight:'600' }}>Funding Source</Typography>
       <Typography>{data.fundingSource}</Typography>
     </Box> */}

                            {/* <Box>
       <Typography sx={{ fontWeight:'600' }}>Purpose</Typography>
       <Typography>{data.purpose}</Typography>
       </Box> */}
                            <Box>
                              <Typography sx={{ fontWeight: '600' }}>Raised Amount</Typography>

                              <Typography>
                                ${data.raisedAmount}</Typography>
                            </Box>
                            <Box>
                              <Typography sx={{ fontWeight: '600' }}>Target Amount</Typography>
                              <Typography>${data.targetAmount}</Typography>
                            </Box>
                            <Box>
                              <Typography sx={{ fontWeight: '600' }}>Created At</Typography>
                              <Typography>{moment(data.createdAt).format('MMMM Do YYYY')}</Typography>
                            </Box>
                            <Box>
                              <Typography sx={{ fontWeight: '600' }}>Target Date</Typography>
                              <Typography>{moment(data.targetDate).format('MMMM Do YYYY')}</Typography>
                            </Box>

                            {/* <Box>
       <Typography sx={{ fontWeight:'600' }}>Withdraw Request</Typography>
       <Typography>{data.withdrawRequest}</Typography>
       </Box> */}
                          </Box>

                          <a href={`/dashboard/viewScholarship/${data._id}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                            <Box sx={{ borderRadius: '0px', textAlign: 'center', background: '#107a0a', padding: '0.5rem 1rem', color: '#fff' }}>
                              View More
                            </Box>
                          </a>
                        </Box>
                      );
                    })
                  )
                }

              </Box>
            )}
          </>
        )}
      </Box>
      <Modal
        open={lightboxOpen}
        onClose={closeLightbox}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <IconButton
            onClick={closeLightbox}
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
              color: "#fff",
            }}
          >
            <MdCancel />
          </IconButton>
          <Box
            sx={{
              position: 'relative',
              // background:'#fff',
              width: {
                xs: '90%',
                sm: '90%',
                md: '400px',
                lg: '500px',
                xl: '500px'
              }
              , height: {
                xs: '90%',
                sm: '90%',
                md: '400px',
                lg: '500px',
                xl: '500px'
              }

            }}>

            <img src={lightboxImage} alt="Lightbox" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'contain', zIndex: "10" }} />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default InstituteDetailPopup;
