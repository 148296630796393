import React, { useContext, useEffect, useState } from "react";
import { Typography, Box, Tab, Tabs,Button } from "@mui/material";
import { httpAPI } from "../../AxiosApi.js";
import moment from "moment";
import { NavLink, useNavigate } from "react-router-dom";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination,
  Skeleton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import '../Admin/Style/commonStyles.scss'

function AdminWallet() {
  const [globalFilter, setGlobalFilter] = useState("");
  const [send, setSend] = useState([]);
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(0);
  const [sendPage, setSendPage] = useState(1);
  const [totalAmount, setTotalAmount] = useState(0);
  const [orderBy, setOrderBy] = useState("title");
  const [order, setOrder] = useState("asc");
  const [role, setRole] = useState("student");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [activeTab, setActiveTab] = useState("all");
  // const itemsPerPage = 12;
  const [sortPreference, setSortPreference] = useState("latest");



  // Function to handle sorting based on the selected preference
  const handleSortPreferenceChange = (event) => {
    setSortPreference(event.target.value);
    // handleSorting(event.target.value)
    if (event.target.value === 'latest') {
      const sortedList = [...data].sort((a, b) => a['createdAt'] < b['createdAt'] ? 1 : -1);
      setData(sortedList);
    }
    if (event.target.value === 'oldest') {
      const sortedList = [...data].sort((a, b) => a['createdAt'] > b['createdAt'] ? 1 : -1);
      setData(sortedList);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = async () => {
    try {
      const response = await httpAPI.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/adminWallet`,
        { page }
      );
      if (response.status === 200) {
        setSend(response.data.transactions);
        setData(response.data.transactions);
        const totalSentAmount = response.data.transactions.reduce(
          (total, transaction) => total + transaction.platformFee,
          0
        );

        setTotalAmount(totalSentAmount);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSendPageChange = (event, newPage) => {
    setSendPage(newPage);
  };

  const handleSorting = (sortBy) => {
    setOrder((prev) => (prev === "asc" ? "desc" : "asc"));
    setOrderBy(sortBy);

    const sortedList = [...data].sort((a, b) => {
      if (sortBy === "requestAmount") {
        const totalA = a["paidAmount"] + a["platformFee"];
        const totalB = b["paidAmount"] + b["platformFee"];
        return order === "asc" ? totalA - totalB : totalB - totalA;
      } else {
        if (order === "asc") {
          return a[sortBy] > b[sortBy] ? 1 : -1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      }
    });

    setData(sortedList);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const filterByValue = (object, searchValue) => {
    return Object.values(object).some((value) => {
      if (value === null) {
        return false;
      }
      if (typeof value === "object" && value !== null) {
        return filterByValue(value, searchValue);
      }
      return value.toString().toLowerCase().includes(searchValue.toLowerCase());
    });
  };
  const filteredData = data.filter((request) =>
    filterByValue(request, globalFilter)
  );

  const sortedData = stableSort(filteredData, getComparator(order, orderBy));

  let displayedData;
  if (activeTab === "student") {
    displayedData = sortedData.filter((request) => !request.scholarshipID);
  } else if (activeTab === "scholarship") {
    displayedData = sortedData.filter((request) => request.scholarshipID)
  } else {
    displayedData = sortedData;
  }

  const studentsCount = sortedData.filter((request) => !request.scholarshipID).length;
  const scholarshipCount = sortedData.filter((request) => request.scholarshipID).length;


  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: {
            xs: 'flex-start',
            sm: 'flex-start',
            md: 'center',

          },
          flexDirection: {
            xs: 'column',
            sm: 'row'
          },
          // padding: "0 20px",
        }}
      >
        <Typography variant="h4">
          Admin Wallet
        </Typography>
        {/* <Box> */}
        <Typography variant="h6">
          Wallet Amount : {totalAmount.toFixed(2)}
        </Typography>
        {/* </Box> */}
      </Box>

      <TabPanel value={value} index={0} >


        <Box
          sx={{
            paddingTop: '1rem',
            // borderBottom: 1,
            borderColor: "divider",
            mb: "0.5rem",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: {
              xs: 'column',
              sm: 'column',
              md: 'row'
            },
            alignItems: {
              xs: 'flex-start',
              sm: 'flex-start',
              md: 'center'
            },
            width: "100%",
            gap: '1rem'
          }}
        >
          {
          /* <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={activeTab}
            onChange={handleChangeTab}
            // variant="fullWidth"
            aria-label="Active and Inactive Tabs"
            sx={{ background: "#eee", padding: "5px", borderRadius: "5px", }}
          >
            <Tab
              label="Student"
              value="student"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#107a0a",
                  color: "#fff",
                  borderRadius: "5px",
                },
              }}
            />
            <Tab
              label="Scholarship"
              value="scholarship"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#107a0a",
                  color: "#fff",
                  borderRadius: "5px",
                },
              }}
            />
          </Tabs> */
          }

          <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            variant="fullWidth"
            aria-label="Active and Inactive Tabs"
            sx={{ background: "#fff", padding: "5px", borderRadius: "0px", width: { xs: '100%', sm: '100%', md: '100%', lg: '50%', xl: '50%' } }}
          >
            <Tab
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                  <span style={{ paddingRight: '10px', fontWeight: "bold" }}>

                    All{" "}
                  </span>
                  <span style={{ backgroundColor: "#212B36", color: "#fff", padding: "5px 10px", borderRadius: "5px" }}>
                    {data.length}
                  </span>
                </Box>
              }
              value="all"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#eaeaea",
                  color: "#000",
                  borderRadius: "5px",
                },
              }}
            />
            <Tab
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                  <span style={{ paddingRight: '10px', fontWeight: "bold" }}>

                    Student{" "}
                  </span>
                  <span style={{ backgroundColor: "#22C55E29", color: "#118D57", padding: "5px 10px", borderRadius: "5px", fontWeight: 900 }}>
                    {studentsCount}
                  </span>
                </Box>
              }
              // label={`Active (${activeStudentsCount})`}
              value="student"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#eaeaea",
                  color: "#000",
                  borderRadius: "5px",
                },
              }}
            />
            <Tab
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                  <span style={{ paddingRight: '10px', fontWeight: "bold" }}>
                    Scholarship{" "}
                  </span>
                  <span style={{ backgroundColor: "#FF563029", color: "#B71D18", padding: "5px 10px", borderRadius: "5px", fontWeight: 900 }}>
                    {scholarshipCount}
                  </span>
                </Box>
              }
              // label={`Inactive (${inactiveStudentsCount})`}
              value="scholarship"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#eaeaea",
                  color: "#000",
                  borderRadius: "5px",
                },
              }}
            />

          </Tabs>
          <FormControl sx={{ m: 1, minWidth: 100, width: 200 }}>
            <InputLabel id="demo-simple-select-helper-label">Sort</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={sortPreference}
              label="Age"
              onChange={handleSortPreferenceChange}
            >
              <MenuItem value="latest">Latest</MenuItem>
              <MenuItem value="oldest">Oldest</MenuItem>
            </Select>

          </FormControl>
          {/* <FormControl>
            <InputLabel id="sort-preference-label">Sort By</InputLabel>
            <Select
              labelId="sort-preference-label"
              id="sort-preference"
              value={sortPreference}
              onChange={handleSortPreferenceChange}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="latest">Latest</MenuItem>
              <MenuItem value="oldest">Oldest</MenuItem>
            </Select>
          </FormControl>   */}
        </Box>

        <TableContainer>
          <Table>
            <TableHead sx={{ background: '#f4f6f8', color: "#637381" }}>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "title"}
                    direction={orderBy === "title" ? order : "asc"}
                    onClick={() =>
                      activeTab === "student"
                        ? handleSorting("receipt.userId.fullName")
                        : handleSorting("scholarshipID.title")
                    }
                  >
                    {activeTab === "student" ? "User Name" : "Title"}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "createdAt"}
                    direction={orderBy === "createdAt" ? order : "asc"}
                    onClick={() =>
                      handleSorting("createdAt")
                    }
                  >
                    Request Date
                  </TableSortLabel>
                </TableCell>
                {activeTab !== "student" && (
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "userID.role"}
                      direction={orderBy === "userID.role" ? order : "asc"}
                      onClick={() => handleSorting("userID.role")}
                    >
                      Role
                    </TableSortLabel>
                  </TableCell>
                )}
                {role === "student" && (
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "requestAmount"}
                      direction={orderBy === "requestAmount" ? order : "asc"}
                      onClick={() => handleSorting("requestAmount")}
                    >
                      Request Amount
                    </TableSortLabel>
                  </TableCell>
                )}
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "paidAmount"}
                    direction={orderBy === "paidAmount" ? order : "asc"}
                    onClick={() => handleSorting("paidAmount")}
                  >
                    Total Paid
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "platformFee"}
                    direction={orderBy === "platformFee" ? order : "asc"}
                    onClick={() => handleSorting("platformFee")}
                  >
                    Platform Fee
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "updatedAt"}
                    direction={orderBy === "updatedAt" ? order : "asc"}
                    onClick={() => handleSorting("updatedAt")}
                  >
                    Approved Date
                  </TableSortLabel>
                </TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                Array.from({ length: 8 }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Skeleton animation="wave" width={"100%"} />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" width={"100%"} />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" width={"100%"} />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" width={"100%"} />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" width={"100%"} />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" width={"100%"} />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" width={"100%"} />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" width={"100%"} />
                    </TableCell>

                  </TableRow>
                ))
              ) : displayedData.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={9}
                    align="center"
                    sx={{ textAlign: "center !important" }}
                  >
                    No data found
                  </TableCell>
                </TableRow>
              ) : (
                // Render filtered scholarship data
                displayedData.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>
                      {activeTab === "student"
                        ? (row.receipt?.userId?.fullName ? row.receipt.userId.fullName : "NA")
                        : (row.title
                          ? (row.title.length > 20
                            ? row.title.substring(0, 20) + "..."
                            : row.title)
                          : (row?.scholarshipID?.title ? row.scholarshipID.title : "NA"))}
                    </TableCell>
                    
                    <TableCell>{moment(row?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</TableCell>
                    {activeTab !== "student" && <TableCell>{row?.userID?.role ? row?.userID?.role : 'NA' }</TableCell>}
                    <TableCell>
                      ${(row?.paidAmount + row?.platformFee).toFixed(2)}
                    </TableCell>
                    <TableCell>${row?.paidAmount.toFixed(2)}</TableCell>
                    <TableCell sx={{ color: "green" }}>
                      +${row?.platformFee.toFixed(2)}
                    </TableCell>
                    {role === "student" ? (
                      <TableCell>
                        {moment(row?.updatedAt).format('MMMM Do YYYY, h:mm:ss a')}
                      </TableCell>
                    ) : (
                      ""
                    )}

                    <TableCell>
                      <NavLink
                        to={
                          (activeTab=="all")?(row?.userID.role==='student'?`/dashboard/viewAdminWallet/${row?.userID._id}`:`/dashboard/viewFundRequest/${row?._id}`):
                          activeTab === "student" ? `/dashboard/viewAdminWallet/${row?.userID._id}` : `/dashboard/viewFundRequest/${row?._id}`}
                        target="_blank"
                        style={{ textDecorationLine: "none", color: "#000" }}
                      >
                        <Button variant="outlined">view</Button>
                      </NavLink>
                      {/* {row.raisedAmount ? row.raisedAmount : "NA"} */}
                    </TableCell>
                    {/* <TableCell>{row.targetAmount}</TableCell> */}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!loading && data && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={displayedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
        <ToastContainer />
      </TabPanel>
    </Box>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default AdminWallet;
