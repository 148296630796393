import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Student from './Components/Admin/Student.js';
// import Institute from './Components/Admin/Institute.js';
import Patron from './Components/Admin/Patron.js';
import Scholarship from './Components/Admin/Scholarship.js';
// import Login from './Components/Auth/Login.js';
import { UserProvider } from './Components/Utils/UserContext.js';
import { AuthProvider } from './Components/Utils/AuthContext.js';
import PrivateRoute from './Components/Utils/PrivateRoute.js';
import ForgetPassword from './Components/Auth/ForgetPassword.js';
import UpdateProfile from './Components/Admin/UpdateProfile.js';
import ChangePassword from './Components/Admin/ChangePassword.js';
import Modal from 'react-modal'; 
import '@fortawesome/fontawesome-free/css/all.min.css';
import FundRequest from './Components/Admin/FundRequest.js';
import ManageScholarShip from './Components/Admin/ManageScholarShip.js';
import Dashboard from './Components/Admin/Dashboard.js';
import Institute from './Components/Admin/Institute.js';
import InstitueDetailsPopup from './Components/Admin/DetailViewPopup/InstituteDetailPopup.js';
import EditInstituteForm from './Components/Admin/EditInstituteForm.js';
import StudentDetailsPopup from './Components/Admin/DetailViewPopup/StudentDetailsPopup.js';
import Login from './Components/Auth/Login.js';
import ScholarshipDetailPopup from './Components/Admin/DetailViewPopup/ScholarshipDetailPopup.js';
import PatronDetailPopup from './Components/Admin/DetailViewPopup/PatronDetailPopup.js';
import AddInstituteForm from './Components/Admin/AddInstituteForm.js';
import FundRequestDetail from './Components/Admin/DetailViewPopup/FundRequestDetail.js';
import ReceiptFileUploader from './Components/Admin/DetailViewPopup/ReceiptFileUploader.js';
import WalletRequest from './Components/Admin/WalletRequest.js';
import WalletFileUploader from './Components/Admin/DetailViewPopup/WalletFileUploader.js';
import ViewWalletRequest from './Components/Admin/DetailViewPopup/ViewWalletRequest.js';
import ViewAdminWallet from './Components/Admin/DetailViewPopup/ViewAdminWallet.js';
import ResetPassword from './Components/Auth/ResetPassword.js';
import Home from './Components/Admin/Home.js';
import AdminWallet from './Components/Admin/AdminWallet.js';
import Transactions from './Components/Admin/Transactions.js';



function App() {
  useEffect(() => {
    Modal.setAppElement('#root');
  }, []);

  return (
    <div className="App">
      <AuthProvider>
          <UserProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Navigate to="/dashboard/home" />} />
                <Route path="/login" element={<Login />} />
                <Route path="/resetPassword" element={<ResetPassword />} />
                <Route path="/forgetPassword" element={<ForgetPassword />} />
                <Route path="/dashboard" element={<PrivateRoute Component={Dashboard} />}>
                  <Route path="home" element={<PrivateRoute Component={Home} />} />
                  <Route path="student" element={<PrivateRoute Component={Student} />} />
                  <Route path="viewStudent/:id" element={<PrivateRoute Component={StudentDetailsPopup} />} />
                  <Route path="institute" element={<PrivateRoute Component={Institute} />} />
                  <Route path="addInstitute" element={<PrivateRoute Component={AddInstituteForm} />} />
                  <Route path="viewInstitute/:id" element={<PrivateRoute Component={InstitueDetailsPopup} />} />
                  <Route path="viewPatron/:id" element={<PrivateRoute Component={PatronDetailPopup} />} />
                  <Route path="editInstitute/:id" element={<PrivateRoute Component={EditInstituteForm} />} />
                  <Route path="patron" element={<PrivateRoute Component={Patron} />} />
                  <Route path="scholarship" element={<PrivateRoute Component={Scholarship} />} />
                  <Route path="walletRequests" element={<PrivateRoute Component={WalletRequest} />} />
                  <Route path="viewScholarship/:id" element={<PrivateRoute Component={ScholarshipDetailPopup} />} />
                  <Route path="viewFundRequest/:id" element={<PrivateRoute Component={FundRequestDetail} />} />
                  <Route path="viewWalletRequest/:id" element={<PrivateRoute Component={ViewWalletRequest} />} />
                  <Route path="viewAdminWallet/:id" element={<PrivateRoute Component={ViewAdminWallet} />} />
                  <Route path="receiptUpload/:userId/search" element={<PrivateRoute Component={ReceiptFileUploader} />} />
                  <Route path="walletReceiptUpload/:userId/search" element={<PrivateRoute Component={WalletFileUploader} />} />
                  <Route path="updateProfile" element={<PrivateRoute Component={UpdateProfile} />} />
                  <Route path="changePassword" element={<PrivateRoute Component={ChangePassword} />} />
                  <Route path="fundRequest" element={<PrivateRoute Component={FundRequest} />} />
                  <Route path="adminWallet" element={<PrivateRoute Component={AdminWallet} />} />
                  <Route path="transactions" element={<PrivateRoute Component={Transactions} />} />
                  <Route path="scholarshipDetail/:scholarshipId" element={<PrivateRoute Component={ManageScholarShip} />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </UserProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
