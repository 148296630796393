import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Tab,
  Tabs,
  Divider,
  Skeleton,
  Slider,
  List,
  ListItem,
  Avatar,
  ListItemText,
  TablePagination,
  TableRow,
  TableCell,
} from "@mui/material";
import { TbSquareRoundedArrowLeftFilled } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";
import { httpAPI } from "../../../AxiosApi.js";
import { MdAccountBox } from "react-icons/md";
import moment from 'moment';

const ScholarshipDetailPopup = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [student, setStudent] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [donorList, setDonorList] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    fetchDonationList();
    fetchStudentDetails();
  }, [id]);

  const fetchStudentDetails = async () => {
    try {
      const instituteId = id
      const response = await httpAPI.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/scholrship/${instituteId}`
      );
      if (response.status === 200) {
        setStudent(response.data.response);
      } else {
        console.error("Failed to fetch student details");
      }
    } catch (error) {
      console.error("Error fetching student details:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDonationList = async () => {
    try {
      const response = await httpAPI.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/scholarshipDonations/${id}`
      );
      if (response.status === 200) {
        setDonorList(response.data.response);
      } else {
        console.error("Failed to fetch donation list");
      }
    } catch (error) {
      console.error("Error fetching donation list:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleGoBack = () => {
    navigate("/dashboard/scholarship");
  };
  const MAX = student?.targetAmount;
  const MIN = 0;
  const marks = [
    {
      value: MIN,
      label: '',
    },
    {
      value: MAX,
      label: '',
    },
  ];


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // 
  const [val, setVal] = React.useState(MIN);
  const handleChange = (_, newValue) => {
    setVal(newValue);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: {
          xs: '0',
          sm: '1rem',
          md: '1rem',
        },
        borderRadius: "10px",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          width: "100%",
          padding: "1rem",
          background: "#eeeeee",
          borderRadius: "5px",
          marginBottom: "2rem",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start", gap: "1rem" }}>
          <span style={{ cursor: "pointer" }} onClick={handleGoBack}>
            <TbSquareRoundedArrowLeftFilled
              style={{ fontSize: "30px", color: "#212529" }}
            />
          </span>
          <Typography variant="h5" style={{ margin: "0" }}>
            Scholarship Details
          </Typography>
        </Box>
      </Box> */}
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          width: '100%',
          padding: "1rem",
          background: "#eeeeee",
          borderRadius: "5px",
          marginBottom: "2rem",
          boxSizing: "border-box",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start", gap: "1rem" }}>
          <span style={{ cursor: "pointer" }} onClick={handleGoBack}>
            <TbSquareRoundedArrowLeftFilled
              style={{ fontSize: "30px", color: "#212529" }}
            />
          </span>
          <Typography variant="h5" style={{ margin: "0" }}>
            Scholarship Details
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "auto",
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            sx={{
              background: "#eee",
              padding: "5px",
              borderRadius: "5px",
              width:'auto'
            }}
          >
            <Tab
              label="Scholarship Details"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#107a0a",
                  color: "#fff",
                  borderRadius: "5px",
                },
              }}
            />
            <Tab
              label="User Detail"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#107a0a",
                  color: "#fff",
                  borderRadius: "5px",
                },
              }}
            />
            <Tab
              label="Donations List"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#107a0a",
                  color: "#fff",
                  borderRadius: "5px",
                },
              }}
            />
          </Tabs>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: "100%",
          padding: "1rem",
          background: "#ffffff",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          boxSizing: "border-box",
        }}
      >
        {loading || !student ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={200} height={30} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={200} height={30} />
            </Box>

            <Divider />

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={200} height={30} />
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={200} height={30} />
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={200} height={30} />
            </Box>
          </>
        ) : (
          <>
            {currentTab === 0 && (
              <>
                <Box sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '50%',
                    lg: '300px',
                    xl: '300px'
                  }, mt: 4
                }}>
                  <Slider
                    marks={marks}
                    value={student.raisedAmount}
                    // valueLabelDisplay="on"
                    min={MIN}
                    max={MAX}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography
                      variant="body2"
                      onClick={() => setVal(MIN)}
                      sx={{ cursor: 'pointer' }}
                    >
                      ${MIN}
                    </Typography>
                    <Typography
                      variant="body2"
                      onClick={() => setVal(MAX)}
                      sx={{ cursor: 'pointer' }}
                    >
                      ${MAX}
                    </Typography>
                  </Box>
                </Box>



                <Box sx={{ display: 'grid', gridTemplateColumns: { xs: '1fr', sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr', xl: '1fr 1fr 1fr' }, gap: '1rem' }}>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: 'column',
                      gap: "5px",
                      backgroundColor: '#f6f6f6',
                      padding: '5px 10px'
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Title:
                    </Typography>
                    <Typography variant="body1">{student.title}</Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: 'column',
                      gap: "5px",
                      backgroundColor: '#f6f6f6',
                      padding: '5px 10px'
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Description:
                    </Typography>
                    <Typography variant="body1">{student.description}</Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: 'column',
                      gap: "5px",
                      backgroundColor: '#f6f6f6',
                      padding: '5px 10px'
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Purpose:
                    </Typography>
                    <Typography variant="body1">{student.purpose}</Typography>
                  </Box>


                  {student.userId.role == "student" ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: 'column',
                          gap: "5px",
                          backgroundColor: '#f6f6f6',
                          padding: '5px 10px'
                        }}
                      >
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Course Name:
                        </Typography>
                        <Typography variant="body1">
                          {student.courseName?.substring(0, 10)}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: 'column',
                          gap: "5px",
                          backgroundColor: '#f6f6f6',
                          padding: '5px 10px'
                        }}
                      >
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Course Start Date:
                        </Typography>
                        <Typography variant="body1">
                          {student.startDate && moment(student.startDate).format('MMM DD, YYYY')}

                        </Typography>
                      </Box>


                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: 'column',
                          gap: "5px",
                          backgroundColor: '#f6f6f6',
                          padding: '5px 10px'
                        }}
                      >
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Course End Date:
                        </Typography>
                        <Typography variant="body1">
                          {student.endDate && moment(student.endDate).format('MMM DD, YYYY')}

                        </Typography>
                      </Box>
                    </>
                  ) : (
                    ""
                  )}


                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: 'column',
                      gap: "5px",
                      backgroundColor: '#f6f6f6',
                      padding: '5px 10px'
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Target Date:
                    </Typography>
                    <Typography variant="body1">
                      {student.targetDate && moment(student.targetDate).format('MMM DD, YYYY')}

                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: 'column',
                      gap: "5px",
                      backgroundColor: '#f6f6f6',
                      padding: '5px 10px'
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Raised Amount:
                    </Typography>
                    <Typography variant="body1">
                     ${student.raisedAmount}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: 'column',
                      gap: "5px",
                      backgroundColor: '#f6f6f6',
                      padding: '5px 10px'
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Target Amount:
                    </Typography>
                    <Typography variant="body1">
                      ${student.targetAmount}
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
            {currentTab === 1 && (
              <>
                {student.userId.role == "student" ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'grid', marginBottom: '1rem', gridTemplateColumns: { xs: '1fr', sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr', xl: '1fr 1fr 1fr' }, gap: '1rem' }}>
                      <Box sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: 'column',
                        gap: "5px",
                        // backgroundColor:'#f6f6f6',
                        padding: '5px 10px'
                      }}>
                        <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/${student.userId.profilePicture}`} style={{ width: '150px' }} />
                      </Box>
                    </Box>
                    <Box>
                      <Box sx={{ display: 'grid', gridTemplateColumns: { xs: '1fr', sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr', xl: '1fr 1fr 1fr' }, gap: '1rem' }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: 'column',
                            gap: "5px",
                            backgroundColor: '#f6f6f6',
                            padding: '5px 10px'
                          }}
                        >
                          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                            Name:
                          </Typography>
                          <Typography variant="body1">
                            {student.userId.fullName}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: 'column',
                            gap: "5px",
                            backgroundColor: '#f6f6f6',
                            padding: '5px 10px'
                          }}
                        >
                          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                            Email:
                          </Typography>
                          <Typography variant="body1">
                            {student.userId.email}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: 'column',
                            gap: "5px",
                            backgroundColor: '#f6f6f6',
                            padding: '5px 10px'
                          }}
                        >
                          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                            Phone No:
                          </Typography>
                          <Typography variant="body1">{`${student.userId.phone?.countryCode}-${student.userId.phone?.phoneNumber}`}</Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: 'column',
                            gap: "5px",
                            backgroundColor: '#f6f6f6',
                            padding: '5px 10px'
                          }}
                        >
                          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                            Date Of Birth:
                          </Typography>
                          <Typography variant="body1">
                            {student.userId.dateOfBirth && moment(student.userId.dateOfBirth).format('MMM DD, YYYY')}

                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "0.5rem",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: "600",
                            fontSize: "22px !important",
                            textDecorationLine: ' underline',
                            marginTop: '1rem'
                          }}
                        >
                          Institute Detail
                        </Typography>
                        <Box
                          sx={{ display: 'grid', gridTemplateColumns: { xs: '1fr', sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr', xl: '1fr 1fr 1fr' }, gap: '1rem' }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: 'column',
                              gap: "5px",
                              backgroundColor: '#f6f6f6',
                              padding: '5px 10px'
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "bold" }}
                            >
                              Name:
                            </Typography>
                            <Typography variant="body1">
                              {student?.instituteId?.fullName}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: 'column',
                              gap: "5px",
                              backgroundColor: '#f6f6f6',
                              padding: '5px 10px'
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "bold" }}
                            >
                              Email:
                            </Typography>
                            <Typography variant="body1">
                              {student?.instituteId?.email}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: 'column',
                              gap: "5px",
                              backgroundColor: '#f6f6f6',
                              padding: '5px 10px'
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "bold" }}
                            >
                              Date Of Estiblishment:
                            </Typography>
                            <Typography variant="body1">
                              {student?.instituteId?.dateOfEstablishment && moment(student?.instituteId?.dateOfEstablishment).format('MMM DD, YYYY')}

                            </Typography>
                          </Box>
                        </Box>
                      </Box>


                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "0.5rem",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: "600",
                            fontSize: "22px !important",
                            textDecorationLine: ' underline',
                            marginTop: '1rem'
                          }}
                        >
                          Institute Address
                        </Typography>
                        <Box
                          sx={{ display: 'grid', gridTemplateColumns: { xs: '1fr', sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr', xl: '1fr 1fr 1fr' }, gap: '1rem' }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: 'column',
                              gap: "5px",
                              backgroundColor: '#f6f6f6',
                              padding: '5px 10px'
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "bold" }}
                            >
                              Street:
                            </Typography>
                            <Typography variant="body1">
                              {student?.instituteId?.address?.street}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: 'column',
                              gap: "5px",
                              backgroundColor: '#f6f6f6',
                              padding: '5px 10px'
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "bold" }}
                            >
                              City:
                            </Typography>
                            <Typography variant="body1">
                              {student?.instituteId?.address?.city}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: 'column',
                              gap: "5px",
                              backgroundColor: '#f6f6f6',
                              padding: '5px 10px'
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "bold" }}
                            >
                              State:
                            </Typography>
                            <Typography variant="body1">
                              {student?.instituteId.address?.state}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: 'column',
                              gap: "5px",
                              backgroundColor: '#f6f6f6',
                              padding: '5px 10px'
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "bold" }}
                            >
                              Pincode:
                            </Typography>
                            <Typography variant="body1">
                              {student?.instituteId.address?.pincode}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: 'column',
                              gap: "5px",
                              backgroundColor: '#f6f6f6',
                              padding: '5px 10px'
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "bold" }}
                            >
                              Country:
                            </Typography>
                            <Typography variant="body1">
                              {student?.instituteId.address?.country}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                ) : (
                  <>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Box sx={{ display: 'grid', marginBottom: '1rem', gridTemplateColumns: { xs: '1fr', sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr', xl: '1fr 1fr 1fr' }, gap: '1rem' }}>
                        <Box sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: 'column',
                          gap: "5px",
                          // backgroundColor:'#f6f6f6',
                          padding: '5px 10px'
                        }}>
                          <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/${student.userId.profilePicture}`} style={{ width: '150px' }} />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "0.5rem",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: "normal",
                            fontStyle: "italic",
                            fontSize: "16px !important",
                          }}
                        >
                          Institute Detail
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: " column",
                            width: "100%",
                            gap: " 1rem",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: 'column',
                              gap: "5px",
                              backgroundColor: '#f6f6f6',
                              padding: '5px 10px'
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "bold" }}
                            >
                              Name:
                            </Typography>
                            <Typography variant="body1">
                              {student.userId.fullName}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: 'column',
                              gap: "5px",
                              backgroundColor: '#f6f6f6',
                              padding: '5px 10px'
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "bold" }}
                            >
                              Email:
                            </Typography>
                            <Typography variant="body1">
                              {student.userId.email}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: 'column',
                              gap: "5px",
                              backgroundColor: '#f6f6f6',
                              padding: '5px 10px'
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "bold" }}
                            >
                              Date Of Estiblishment:
                            </Typography>
                            <Typography variant="body1">
                              {student.userId.dateOfEstablishment && moment(student.userId.dateOfEstablishment).format('MMM DD, YYYY')}

                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Divider />

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "0.5rem",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "normal",
                          fontStyle: "italic",
                          fontSize: "16px !important",
                        }}
                      >
                        Institute Address
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: " column",
                          width: "100%",
                          gap: " 1rem",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: 'column',
                            gap: "5px",
                            backgroundColor: '#f6f6f6',
                            padding: '5px 10px'
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            Street:
                          </Typography>
                          <Typography variant="body1">
                            {student.userId.address.street}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: 'column',
                            gap: "5px",
                            backgroundColor: '#f6f6f6',
                            padding: '5px 10px'
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            City:
                          </Typography>
                          <Typography variant="body1">
                            {student.userId.address?.city}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: 'column',
                            gap: "5px",
                            backgroundColor: '#f6f6f6',
                            padding: '5px 10px'
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            State:
                          </Typography>
                          <Typography variant="body1">
                            {student.userId.address?.state}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: 'column',
                            gap: "5px",
                            backgroundColor: '#f6f6f6',
                            padding: '5px 10px'
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            Pincode:
                          </Typography>
                          <Typography variant="body1">
                            {student.userId.address?.pincode}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: 'column',
                            gap: "5px",
                            backgroundColor: '#f6f6f6',
                            padding: '5px 10px'
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            Country:
                          </Typography>
                          <Typography variant="body1">
                            {student.userId.address?.country}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}
              </>
            )}
            {currentTab === 2 && (
              <>
                {donorList && donorList.length > 0 ? (
                  <>
                    {donorList && donorList.length > 0 ? (
                      <>
                        <List
                          sx={{
                            width: "100%",
                            maxWidth: "100%",
                            bgcolor: "background.paper",
                            display: 'grid',
                            gridTemplateColumns: {
                              xs: 'repeat(1, 1fr)',
                              sm: 'repeat(1, 1fr)',
                              md: 'repeat(2, 1fr)',
                              lg: 'repeat(3, 1fr)',
                              xl: 'repeat(3, 1fr)'
                            },
                            gap: '1rem'
                          }}
                        >
                          {(rowsPerPage > 0
                            ? donorList.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            : donorList
                          ).map((row) => (
                            <React.Fragment key={row._id}>
                              <ListItem alignItems="flex-start" sx={{ padding: '0rem', border: '0.5px solid gainsboro', borderRadius: '5px' }}>
                                <Box sx={{ padding:'0.5rem' }}>
                                  {row?.patronID?.profilePicture ? (
                                    <img
                                      src={`${process.env.REACT_APP_BACKEND_URL}/uploads/${row?.patronID?.profilePicture}`}
                                      alt="Profile"
                                      style={{
                                        width: 48,
                                        height: 48,
                                        // borderRadius: "50%",
                                        borderRadius: '5px',
                                        // marginRight: "10px",
                                        border: '0.5px solid #efefef'
                                      }}
                                    />
                                  ) : (
                                    <MdAccountBox style={{
                                      // marginRight: "10px",
                                      width: 48,
                                      height: 48,
                                      // borderRadius: "50%",
                                      borderRadius: '5px',
                                      marginRight: "10px",
                                      border: '0.5px solid #efefef',
                                      color: 'rgb(217 217 217)'
                                    }}>
                                      {row?.scholarshipID?.userId?.fullName.charAt(
                                        0
                                      )}
                                    </MdAccountBox>
                                  )}
                                </Box>
                                <ListItemText
                                sx={{ mt:'0', mb:'0' }}
                                  // primary={row?.scholarshipID?.title }
                                  secondary={
                                    <Box sx={{ borderLeft: '0.5px solid #efefef', }}>
                                      <Box sx={{ padding:'0.5rem' }}>
                                        <Typography color="text.primary" sx={{ fontWeight: '700' }}>
                                          {row?.patronID?.fullName}
                                        </Typography>
                                      </Box>
                                      <Divider />
                                      <Box sx={{ padding: '0.5rem' }}>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            width: "100%",
                                            
                                          }}
                                        >
                                          <Typography
                                            variant="body2"
                                            color="text.primary"
                                            sx={{ fontWeight: '500' }}
                                          >
                                            Amount:
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            color="text.primary"
                                          >
                                            {`$${row.amount}`}
                                          </Typography>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            width: "100%",
                                          }}
                                        >
                                          <Typography
                                            variant="body2"
                                            color="text.primary"
                                            sx={{ fontWeight: '500' }}
                                          >
                                            Date:
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            color="text.primary"
                                          >
                                            {row?.patronID?.createdAt && moment(row?.patronID?.createdAt).format('MMM DD, YYYY')}

                                          </Typography>
                                        </Box>
                                     </Box>
                                    </Box>
                                  }
                                />
                              </ListItem>
                              {/* <Divider variant="inset" component="li" /> */}
                            </React.Fragment>
                          ))}
                        </List>

                        {/* Pagination */}
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={donorList.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </>
                    ) : (
                      // No data found
                      <TableRow>
                        <TableCell colSpan={4} align="center" sx={{ textAlign: 'center !important' }}>
                          No data found
                        </TableCell>
                      </TableRow>
                    )
                    }
                  </>
                ) : (
                  // No data found
                  <TableRow>
                    <TableCell colSpan={4} align="center" sx={{ textAlign: 'center !important' }}>
                      No data found
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ScholarshipDetailPopup;
