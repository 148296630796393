import React, { useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TextField, Button, CircularProgress, Box } from "@mui/material";
import loginImg from "../../assets/images/login-img.png";
import { TbSquareRoundedArrowLeftFilled } from "react-icons/tb";

export default function ForgetPassword() {
  const navigate = useNavigate();
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (!isSendingEmail) {
          setIsSendingEmail(true);
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/auth/forgetPassword`,
            values
          );
          if (response.status === 200) {
            toast.success("Forget Password Sent Successfully on your Email");
          } else if (response.status === 201) {
            toast.error(response?.data?.message);
          } else {
            toast.error("Unexpected Error: Please try again later.");
          }
          setIsSendingEmail(false);
        } else {
          toast.warning("Please wait before sending another email.");
        }
      } catch (error) {
        console.error("Error sending reset email:", error);
        toast.error("Error sending reset email. Please try again later.");
        setIsSendingEmail(false);
      }
    },
  });

  const handleGoBack = () => {
    navigate("/login");
  };

  return (
    <Box>
      <Box sx={{ padding: "1rem", overflow: "hidden", height: "calc(100vh - 2rem)" }}>
        <Box
          sx={{
            height: "100%",
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr ",
              sm: "1fr",
              md: "1fr",
              lg: "1fr 1fr",
            },
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              background: "#107a0a",
              display: { xs: "none", sm: "none", md: "none", lg: "flex" },
              justifyContent: "center",
              alignItems: "center",
              padding: "1rem",
             
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <h1 style={{ color: "#fff" }}>CrowdFunding</h1>
              <img
                src={loginImg}
                alt="Login"
                style={{ display: "block", width: "100%" }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "1rem",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "1rem",
                position: "absolute",
                top: { xs: "1rem", sm: "1rem", md: "1rem", lg: "2rem" },
                left: { xs: "1rem", sm: "1rem", md: "1rem", lg: "2rem" },
              }}
            >
              <span style={{ cursor: "pointer" }} onClick={handleGoBack}>
                <TbSquareRoundedArrowLeftFilled
                  style={{ fontSize: "30px", color: "#212529" }}
                />
              </span>
              <h2 style={{ margin: "0" }}>Back</h2>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: "1rem",
                width: {
                  xs: '100%',
                  sm:'100%',
                  md:'50%',
                  lg:'50%',
                  xl:'50%'
                }
              }}
            >
              <h1>Forgot Password</h1>
              <p>
                Please enter your registered email to receive a password reset
                link.
              </p>
              <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  variant="outlined"
                  sx={{ mb: 2 }}
                />
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={isSendingEmail}
                  sx={{
                    background: "#107a0a",
                    padding: "0.8rem",
                    fontWeight: "600",
                    fontSize: "16px",
                  }}
                >
                  {isSendingEmail ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Send Reset Email"
                  )}
                </Button>
              </form>
            </Box>
          </Box>
        </Box>
      </Box>
      <ToastContainer />
    </Box>
  );
}
