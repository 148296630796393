// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manageScholarship{
    width: 100%;

}
.navBar{
    width: 100%;
}
.navBar ul{
    width: 96%;
    display: flex;
    gap: 40px;
box-shadow: 2px 0.2px 8px rgba(128, 128, 128, 0.603);
}
.navBar ul li{
    list-style: none;
    font-size: 20px;
    padding: 10px 0;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Components/Admin/Style/ManageScholarship.css"],"names":[],"mappings":"AAAA;IACI,WAAW;;AAEf;AACA;IACI,WAAW;AACf;AACA;IACI,UAAU;IACV,aAAa;IACb,SAAS;AACb,oDAAoD;AACpD;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,eAAe;IACf,eAAe;AACnB","sourcesContent":[".manageScholarship{\n    width: 100%;\n\n}\n.navBar{\n    width: 100%;\n}\n.navBar ul{\n    width: 96%;\n    display: flex;\n    gap: 40px;\nbox-shadow: 2px 0.2px 8px rgba(128, 128, 128, 0.603);\n}\n.navBar ul li{\n    list-style: none;\n    font-size: 20px;\n    padding: 10px 0;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
