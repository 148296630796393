import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../Utils/UserContext.js";
import { useAuth } from "../Utils/AuthContext.js";
import { useFormik } from "formik";
import * as Yup from "yup";
// import "./Login.css";
import { useNavigate, Link, NavLink } from "react-router-dom";
import { httpAPI } from "../../AxiosApi.js";
import loginImg from "../../assets/images/login-img.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  CircularProgress, 
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const Login = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false); 

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { setUser } = useContext(UserContext);
  const { login } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      navigate("/dashboard/home");
    }
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true); 
        const response = await httpAPI.post(
          `${process.env.REACT_APP_BACKEND_URL}/auth/login`,
          values
        );

        if (response.status === 200 && response?.data?.response?.data) {
          const authToken = response.data.response.authToken;
          const userObject = response.data.response.data;
          setUser(userObject);
          localStorage.setItem("authToken", authToken);
          localStorage.setItem("user", JSON.stringify(userObject));
          login(authToken);

          if (response.data.response.data.role === "admin") {
            navigate("/dashboard/home");
          }
        } else {
          toast.error(`${response.data.message}`);
          console.log(response.data)
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            toast.error(error.response.data.message);

          } else {
            toast.error("An error occurred:", error.message);
          }
        }
      } finally {
        setIsSubmitting(false); // Set isSubmitting to false after form submission
      }
    },
  });

  return (
    <Box>
      <Box sx={{ padding: "1rem", overflow: "hidden", height: "calc(100vh - 2rem)" }}>
        <Box
          sx={{
            height: "100%",
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr ",
              sm: "1fr",
              md: "1fr",
              lg: "1fr 1fr",
            },
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              background: "#107a0a",
              display: { xs: "none", sm: "none", md: "none", lg: "flex" },
              justifyContent: "center",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <h1 style={{ color: "#fff" }}>CrowdFunding</h1>
              <img
                src={loginImg}
                alt="Login"
                style={{ display: "block", width: "100%" }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: "1rem",
                width: {
                  xs: '100%',
                  sm:'100%',
                  md:'50%',
                  lg:'50%',
                  xl:'50%'
                }
              }}
            >
              <h1>CrowdFunding Admin</h1>
              <p>Please login with your credentials</p>
              <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "1rem",
                    flexDirection: "column",
                  }}
                >
                  <TextField
                    id="email"
                    label="Email Address"
                    variant="outlined"
                    type="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    fullWidth
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />

                  <FormControl
                    sx={{ width: "100%", boxSizing: "border-box" }}
                    variant="outlined"
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    />
                  </FormControl>

                  <Box
                    className=""
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <NavLink to="/forgetPassword" className="forgot" style={{textDecoration:"none"}}>
                      Forgot Password
                    </NavLink>
                  </Box>

                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      width: "100%",
                      background: "#107a0a",
                      padding: "0.8rem",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    {isSubmitting ? <CircularProgress size={24} /> : "Log In"}
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      </Box>
      <ToastContainer/>
    </Box>
  );
};

export default Login;
