import React, { useState, useEffect } from "react";
import {
  Box, Typography, Tab, Tabs, Divider, Skeleton, Slider, Modal,
  Backdrop,
  IconButton,
} from "@mui/material";
import { TbSquareRoundedArrowLeftFilled } from "react-icons/tb";
import { Link, useNavigate, useParams } from "react-router-dom";
import { httpAPI } from "../../../AxiosApi.js";
import { CleanHands } from "@mui/icons-material";
import { MdCancel, MdFileDownload } from "react-icons/md";
import moment from 'moment';
// import { Button } from "bootstrap";


const FundRequestDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [student, setStudent] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxImage, setLightboxImage] = useState(null);

  useEffect(() => {
    const fetchStudentDetails = async () => {
      try {
        const response = await httpAPI.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/requestDetail/${id}`
        );

        if (response.status === 200) {
          setStudent(response.data.response);
          console.log(response.data.response)
        } else {
          console.error("Failed to fetch student details");
        }
      } catch (error) {
        console.error("Error fetching student details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStudentDetails();
  }, [id]);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleGoBack = () => {
    navigate("/dashboard/fundRequest");
  };
  const openLightbox = (imageUrl) => {
    setLightboxImage(imageUrl);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const downloadImage = () => {
    const anchor = document.createElement("a");
    anchor.href = lightboxImage;
    anchor.download = "image.jpg";
    anchor.click();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: {
          xs: '0',
          sm: '1rem',
          md: '1rem',
        },
        borderRadius: "10px",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          width: '100%',
          padding: "1rem",
          background: "#eeeeee",
          borderRadius: "5px",
          marginBottom: "2rem",
          boxSizing: "border-box",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start", gap: "1rem" }}>
          <span style={{ cursor: "pointer" }} onClick={handleGoBack}>
            <TbSquareRoundedArrowLeftFilled
              style={{ fontSize: "30px", color: "#212529" }}
            />
          </span>
          <Typography variant="h5" style={{ margin: "0" }}>
            View Fund Request
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "auto",
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            sx={{
              background: "#eee",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            <Tab
              label="Scholarship"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#107a0a",
                  color: "#fff",
                  borderRadius: "5px",
                },
              }}
            />
            <Tab
              label="User Details"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#107a0a",
                  color: "#fff",
                  borderRadius: "5px",
                },
              }}
            />
            <Tab
              label="Bank Details"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#107a0a",
                  color: "#fff",
                  borderRadius: "5px",
                },
              }}
            />
            {student?.receipt && 
             <Tab
             label="Paid Receipt Details"
             sx={{
               "&.Mui-selected": {
                 backgroundColor: "#107a0a",
                 color: "#fff",
                 borderRadius: "5px",
               },
             }}
           />
            }
            {!student?.receipt && 
             <Tab
             label="Upload Receipt"
             component={Link}
             to={`/dashboard/receiptUpload/${student?.userID?._id}/search?scholarshipId=${student?.scholarshipID?._id}&&amount=${student?.paidAmount+student?.platformFee}&&email=${student?.userID?.email}`}
             sx={{
               "&.Mui-selected": {
                 backgroundColor: "#107a0a",
                 color: "#fff",
                 borderRadius: "5px",
               },
             }}
             
           />
            }
          </Tabs>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: "100%",
          padding: "1rem",
          background: "#ffffff",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          boxSizing: "border-box",
        }}
      >
        {loading || !student ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={200} height={30} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={200} height={30} />
            </Box>

            <Divider />

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={200} height={30} />
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={200} height={30} />
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={200} height={30} />
            </Box>
          </>
        ) : (
          <>
            {currentTab === 0 && (
              <Box sx={{ display: 'grid', gridTemplateColumns: { xs: '1fr', sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr', xl: '1fr 1fr 1fr' }, gap: '1rem' }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: 'column',
                    gap: "5px",
                    backgroundColor: '#f6f6f6',
                    padding: '5px 10px'
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Title:
                  </Typography>
                  <Typography variant="body1">
                    {student?.scholarshipID?.title}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: 'column',
                    gap: "5px",
                    backgroundColor: '#f6f6f6',
                    padding: '5px 10px'
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Description:
                  </Typography>
                  <Typography variant="body1">
                    {student?.scholarshipID?.description}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: 'column',
                    gap: "5px",
                    backgroundColor: '#f6f6f6',
                    padding: '5px 10px'
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Purpose:
                  </Typography>
                  <Typography variant="body1">
                    {student?.scholarshipID?.purpose}
                  </Typography>
                </Box>


                {student?.userID?.role == "student" ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: 'column',
                        gap: "5px",
                        backgroundColor: '#f6f6f6',
                        padding: '5px 10px'
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Course Name:
                      </Typography>
                      <Typography variant="body1">
                        {student?.scholarshipID?.courseName?.substring(0, 10)}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: 'column',
                        gap: "5px",
                        backgroundColor: '#f6f6f6',
                        padding: '5px 10px'
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Course Start Date:
                      </Typography>
                      <Typography variant="body1">
                        {student.scholarshipID?.startDate && moment(student.scholarshipID?.startDate).format('MMM DD, YYYY')}

                      </Typography>
                    </Box>


                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: 'column',
                        gap: "5px",
                        backgroundColor: '#f6f6f6',
                        padding: '5px 10px'
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Course End Date:
                      </Typography>
                      <Typography variant="body1">
                        {student.scholarshipID?.endDate && moment(student.scholarshipID?.endDate).format('MMM DD, YYYY')}

                      </Typography>
                    </Box>
                  </>
                ) : (
                  ""
                )}


                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: 'column',
                    gap: "5px",
                    backgroundColor: '#f6f6f6',
                    padding: '5px 10px'
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Target Date:
                  </Typography>
                  <Typography variant="body1">
                    {student.scholarshipID?.targetDate && moment(student.scholarshipID?.targetDate).format('MMM DD, YYYY')}

                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: 'column',
                    gap: "5px",
                    backgroundColor: '#f6f6f6',
                    padding: '5px 10px'
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Raised Amount:
                  </Typography>
                  <Typography variant="body1">
                    {student?.scholarshipID?.raisedAmount}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: 'column',
                    gap: "5px",
                    backgroundColor: '#f6f6f6',
                    padding: '5px 10px'
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Target Amount:
                  </Typography>
                  <Typography variant="body1">
                    {student?.scholarshipID?.targetAmount}
                  </Typography>
                </Box>

              </Box>
            )}
            {currentTab === 1 && (
              <>
                {student.userID.role == "student" ? (
                  <>

                    <Box sx={{ display: 'grid', gridTemplateColumns: { xs: '1fr', sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr', xl: '1fr 1fr 1fr' }, gap: '1rem' }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: 'column',
                          gap: "5px",
                          backgroundColor: '#f6f6f6',
                          padding: '5px 10px'
                        }}
                      >
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Name:
                        </Typography>
                        <Typography variant="body1">
                          {student?.userID?.fullName}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: 'column',
                          gap: "5px",
                          backgroundColor: '#f6f6f6',
                          padding: '5px 10px'
                        }}
                      >
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Email:
                        </Typography>
                        <Typography variant="body1">
                          {student?.userID?.email}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: 'column',
                          gap: "5px",
                          backgroundColor: '#f6f6f6',
                          padding: '5px 10px'
                        }}
                      >
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Phone No:
                        </Typography>
                        <Typography variant="body1">{`${student?.userID?.phone?.countryCode}-${student?.userID?.phone?.phoneNumber}`}</Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: 'column',
                          gap: "5px",
                          backgroundColor: '#f6f6f6',
                          padding: '5px 10px'
                        }}
                      >
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Date Of Birth:
                        </Typography>
                        <Typography variant="body1">
                          {student.userID?.dateOfBirth && moment(student.userID?.dateOfBirth).format('MMM DD, YYYY')}

                        </Typography>
                      </Box>

                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "0.5rem",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "600",
                          fontSize: "22px !important",
                          textDecorationLine: ' underline'
                        }}
                      >
                        Institute Detail
                      </Typography>
                      <Box
                        sx={{ display: 'grid', gridTemplateColumns: { xs: '1fr', sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr', xl: '1fr 1fr 1fr' }, gap: '1rem' }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: 'column',
                            gap: "5px",
                            backgroundColor: '#f6f6f6',
                            padding: '5px 10px'
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            Name:
                          </Typography>
                          <Typography variant="body1">
                            {student?.instituteID?.fullName}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: 'column',
                            gap: "5px",
                            backgroundColor: '#f6f6f6',
                            padding: '5px 10px'
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            Email:
                          </Typography>
                          <Typography variant="body1">
                            {student?.instituteID?.email}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: 'column',
                            gap: "5px",
                            backgroundColor: '#f6f6f6',
                            padding: '5px 10px'
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            Date Of Establishment:
                          </Typography>
                          <Typography variant="body1">

                            {student?.instituteID?.dateOfEstablishment && moment(student?.instituteID?.dateOfEstablishment).format('MMM DD, YYYY')}

                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "0.5rem",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "600",
                          fontSize: "22px !important",
                          textDecorationLine: ' underline'
                        }}
                      >
                        Institute Address
                      </Typography>
                      <Box
                        sx={{ display: 'grid', gridTemplateColumns: { xs: '1fr', sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr', xl: '1fr 1fr 1fr' }, gap: '1rem' }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: 'column',
                            gap: "5px",
                            backgroundColor: '#f6f6f6',
                            padding: '5px 10px'
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            Street:
                          </Typography>
                          <Typography variant="body1">
                            {student?.instituteID?.address?.street}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: 'column',
                            gap: "5px",
                            backgroundColor: '#f6f6f6',
                            padding: '5px 10px'
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            City:
                          </Typography>
                          <Typography variant="body1">
                            {student?.instituteID?.address?.city}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: 'column',
                            gap: "5px",
                            backgroundColor: '#f6f6f6',
                            padding: '5px 10px'
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            State:
                          </Typography>
                          <Typography variant="body1">
                            {student?.instituteID?.address?.state}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: 'column',
                            gap: "5px",
                            backgroundColor: '#f6f6f6',
                            padding: '5px 10px'
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            Pincode:
                          </Typography>
                          <Typography variant="body1">
                            {student?.instituteID?.address?.pincode}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: 'column',
                            gap: "5px",
                            backgroundColor: '#f6f6f6',
                            padding: '5px 10px'
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            Country:
                          </Typography>
                          <Typography variant="body1">
                            {student?.instituteID?.address?.country}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "0.5rem",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "normal",
                          fontStyle: "italic",
                          fontSize: "16px !important",
                        }}
                      >
                        Institute Detail
                      </Typography>
                      <Box
                        sx={{ display: 'grid', gridTemplateColumns: { xs: '1fr', sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr', xl: '1fr 1fr 1fr' }, gap: '1rem' }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: 'column',
                            gap: "5px",
                            backgroundColor: '#f6f6f6',
                            padding: '5px 10px'
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            Name:
                          </Typography>
                          <Typography variant="body1">
                            {student?.userID?.fullName}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: 'column',
                            gap: "5px",
                            backgroundColor: '#f6f6f6',
                            padding: '5px 10px'
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            Email:
                          </Typography>
                          <Typography variant="body1">
                            {student?.userID?.email}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: 'column',
                            gap: "5px",
                            backgroundColor: '#f6f6f6',
                            padding: '5px 10px'
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            Date Of Establishment:
                          </Typography>
                          <Typography variant="body1">
                            {student?.userID?.dateOfEstablishment && moment(student?.userID?.dateOfEstablishment).format('MMM DD, YYYY')}

                          </Typography>
                        </Box>
                      </Box>
                    </Box>


                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "0.5rem",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "normal",
                          fontSize: "16px !important",
                        }}
                      >
                        Institute Address
                      </Typography>
                      <Box
                        sx={{ display: 'grid', gridTemplateColumns: { xs: '1fr', sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr', xl: '1fr 1fr 1fr' }, gap: '1rem' }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: 'column',
                            gap: "5px",
                            backgroundColor: '#f6f6f6',
                            padding: '5px 10px'
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            Street:
                          </Typography>
                          <Typography variant="body1">
                            {student?.userID?.address?.street}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: 'column',
                            gap: "5px",
                            backgroundColor: '#f6f6f6',
                            padding: '5px 10px'
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            City:
                          </Typography>
                          <Typography variant="body1">
                            {student?.userID?.address?.city}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: 'column',
                            gap: "5px",
                            backgroundColor: '#f6f6f6',
                            padding: '5px 10px'
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            State:
                          </Typography>
                          <Typography variant="body1">
                            {student?.userID?.address?.state}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: 'column',
                            gap: "5px",
                            backgroundColor: '#f6f6f6',
                            padding: '5px 10px'
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            Pincode:
                          </Typography>
                          <Typography variant="body1">
                            {student?.userID?.address?.pincode}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: 'column',
                            gap: "5px",
                            backgroundColor: '#f6f6f6',
                            padding: '5px 10px'
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            Country:
                          </Typography>
                          <Typography variant="body1">
                            {student?.userID?.address?.country}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}
              </>
            )}
            {currentTab === 2 && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "0.5rem",
                    flexDirection: "column",
                  }}
                >

                  <Box
                    sx={{ display: 'grid', gridTemplateColumns: { xs: '1fr', sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr', xl: '1fr 1fr 1fr' }, gap: '1rem' }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: 'column',
                        gap: "5px",
                        backgroundColor: '#f6f6f6',
                        padding: '5px 10px'
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Bank Name:
                      </Typography>
                      <Typography variant="body1">
                        {student?.userID.bankDetails?.bankName}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: 'column',
                        gap: "5px",
                        backgroundColor: '#f6f6f6',
                        padding: '5px 10px'
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Account Holder Name:
                      </Typography>
                      <Typography variant="body1">
                        {student?.userID.bankDetails?.ownerName}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: 'column',
                        gap: "5px",
                        backgroundColor: '#f6f6f6',
                        padding: '5px 10px'
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Account Number:
                      </Typography>
                      <Typography variant="body1">
                        {student?.userID.bankDetails?.accountNumber}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: 'column',
                        gap: "5px",
                        backgroundColor: '#f6f6f6',
                        padding: '5px 10px'
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Routing Number:
                      </Typography>
                      <Typography variant="body1">
                        {student?.userID.bankDetails?.bankCode}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
            {currentTab === 3 && (
              <>
              {student?.receipt && 
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'grid', marginBottom: '1rem', gridTemplateColumns: { xs: '1fr', sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr', xl: '1fr 1fr 1fr' }, gap: '1rem' }}>
                <Box sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: 'column',
                  gap: "5px",
                  padding: '5px 10px'
                }}>
                  {student?.receipt?.receiptFile?.endsWith('.pdf') ? (
                    <a href={`${process.env.REACT_APP_BACKEND_URL}/uploads/${student?.receipt?.receiptFile}`} target="_blank" rel="noopener noreferrer"
                      style={{ width: "100px", color: "#fff", backgroundColor: "green", padding: "10px", textAlign: "center", fontSize: "16px", borderRadius: "15px", textDecoration: "none" }}>View PDF</a>
                  ) : (
                    <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/${student?.receipt?.receiptFile}`} style={{ width: '150px', cursor: 'pointer' }}
                      onClick={() => openLightbox(`${process.env.REACT_APP_BACKEND_URL}/uploads/${student?.receipt?.receiptFile}`)}
                      alt="Profile" />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: 'column',
                  gap: "5px",
                  backgroundColor: '#f6f6f6',
                  padding: '5px 10px'
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Transaction ID:
                </Typography>
                <Typography variant="body1">{student?.receipt?.transactionId}</Typography>
              </Box>
            </Box>
              }
                
              </>
            )}
{/* {currentTab == 3 && (

)} */}
          </>
        )}
      </Box>
      <Modal
        open={lightboxOpen}
        onClose={closeLightbox}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <IconButton
            onClick={closeLightbox}
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
              color: "#fff",
            }}
          >
            <MdCancel />
          </IconButton>
          {/* Image with download button */}
          <Box
            sx={{
              position: 'relative',
              width: "90%",
              height: "90%",
            }}
          >
            <img src={lightboxImage} alt="Lightbox" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'contain', zIndex: "10" }} />
            {/* Download button */}
            <Box
              onClick={downloadImage}
              sx={{
                position: "absolute",
                bottom: "20px",
                right: "20px",
                color: "#fff",
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
                backgroundColor: 'green',
                padding: '1rem',
                fontSize: '20px',
                cursor: "pointer"
              }}
            >
              <MdFileDownload />
              Download
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default FundRequestDetail;
