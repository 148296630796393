// UserProvider.js
import React, { createContext, useState, useEffect, useContext } from 'react';

export const UserContext = createContext();

export function UserProvider({ children }) {
  const [user, setUser] = useState();

  useEffect(() => {
    const user = localStorage.getItem('user');

    if (user) {
      const userObject = user ? JSON.parse(user) : null;

      if (userObject) {
        setUser(userObject);
      }
    }
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}
