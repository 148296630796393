import axios from "axios";

const backendURL = process.env.REACT_APP_BACKEND_URL;

export const httpAPI = axios.create({
  baseURL: backendURL,
});

httpAPI.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
