// PrivateRoute.js

import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function PrivateRoute(props) {
  const {Component} = props;
  const navigate = useNavigate();

  useEffect(()=>{
      let token = localStorage.getItem('authToken');
      if(!token){
          navigate('/login');
      }
  })

  return(
      <div>
          <Component/>
      </div>
  )
  }
