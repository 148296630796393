import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdPictureAsPdf, MdReceipt } from "react-icons/md";
import { httpAPI } from "../../../AxiosApi.js";
import { TbSquareRoundedArrowLeftFilled } from "react-icons/tb";

const ReceiptFileUploader = () => {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [fileName, setFileName] = useState("");
  const [isUploaded, setIsUploaded] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  // const []

  const queryParams = new URLSearchParams(location.search);
  const scholarshipId = queryParams.get("scholarshipId");
  const amount = queryParams.get("amount");
  const email = queryParams.get("email");
  const { userId } = useParams();

  const validationSchema = Yup.object().shape({
    transactionId: Yup.string().required("Transaction ID is required"),
    file: Yup.mixed().required("Receipt File is required"),
  });

  const formik = useFormik({
    initialValues: {
      transactionId: "",
      file: null,
      userId: "",
      scholarshipId: "",
      amount: "",
      email: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await handleSubmit(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      setFile(null);
      setFileName("");
      setPreview(null);
      return;
    }

    formik.setFieldValue("file", selectedFile);
    setFile(selectedFile);
    setFileName(selectedFile.name);
    if (selectedFile.type.startsWith("image/")) {
      setPreview(URL.createObjectURL(selectedFile));
    } else if (selectedFile.type === "application/pdf") {
      setPreview("pdf");
    } else {
      setPreview(null);
    }
  };

  async function handleSubmit(values) {
    const formData = new FormData();
    formData.append("transactionId", values.transactionId);
    formData.append("receipt", values.file);
    formData.append("userId", userId);
    formData.append("amount", amount);
    formData.append("email", email);
    formData.append("scholarshipId", scholarshipId);

    try {
      await validationSchema.validate(values, { abortEarly: false });

      setIsSubmitting(true);
      const response = await httpAPI.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/upload-receipt`,
        formData
      );
      if (response && response.data) {
        toast.success("File uploaded successfully");
        setFile(null);
        formik.resetForm();
        setIsUploaded(true);
        setPreview(null);
        navigate("/dashboard/fundRequest");
      } else {
        toast.error("Failed to upload file");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error(error.response.data.message);
    } finally {
      setIsSubmitting(false);
      formik.setSubmitting(false);
    }
  }
  const handleTransactionIdChange = (event) => {
    const trimmedValue = event.target.value.trim();
    setTransactionId(trimmedValue);
    formik.setFieldValue("transactionId", trimmedValue);
  };

  const handleGoBack = () => {
    navigate("/dashboard/fundRequest");
    setIsUploaded(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: {
          xs: "0",
          sm: "1rem",
          md: "1rem",
        },
        borderRadius: "10px",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          width: "100%",
          padding: "1rem",
          background: "#eeeeee",
          borderRadius: "5px",
          marginBottom: "2rem",
          boxSizing: "border-box",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start", gap: "1rem" }}>
          <span style={{ cursor: "pointer" }} onClick={handleGoBack}>
            <TbSquareRoundedArrowLeftFilled
              style={{ fontSize: "30px", color: "#212529" }}
            />
          </span>
          <Typography variant="h5" style={{ margin: "0" }}>
            Fund Requests
          </Typography>
          {/* <h2 style={{ margin: "0" }}>Student Details</h2> */}
        </Box>
      </Box>
      <Box
        className="update-profile"
        sx={{ boxSizing: "border-box", width: "100%" }}
      >
        <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
          <Box
            sx={{
              margin: "auto",
              width: { xs: "auto", sm: "auto", md: "auto", lg: "50%" },
              padding: "2rem",
            }}
          >
            <Box>
              <Box
                sx={{
                  width: "200px",
                  height: "200px",
                  margin: "auto",
                  border: "1px solid gray",
                  maxHeight: "200px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {preview === "pdf" ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: " center",
                        flexDirection: "column",
                      }}
                    >
                      <MdPictureAsPdf
                        alt="PDF Preview"
                        style={{ fontSize: "70px", color: "red" }}
                      />
                      <Typography variant="body1" align="center">
                        {formik.values.file ? formik.values.file.name : ""}
                      </Typography>
                    </Box>
                  </>
                ) : preview ? (
                  <img
                    src={preview}
                    alt="Preview"
                    style={{
                      width: "200px",
                      height: "200px",
                      borderRadius: "5px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <MdReceipt
                    alt="Preview"
                    style={{
                      fontSize: "70px",
                    }}
                  />
                )}
              </Box>
              {formik.touched.file && (
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    color: "red",
                  }}
                >
                  {formik.touched.file && formik.errors.file}
                </span>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "2rem 0",
                }}
              >
                <input
                  accept=".png,.jpg,.jpeg,.pdf"
                  style={{ display: "none" }}
                  id="file-upload"
                  type="file"
                  onChange={(e) => handleFileChange(e)}
                  error={formik.touched.file && Boolean(formik.errors.file)}
                  helperText={formik.touched.file && formik.errors.file}
                  sx={{ mb: 2 }}
                />
                <label htmlFor="file-upload">
                  <Button variant="contained" component="span">
                    Upload Receipt
                  </Button>
                </label>
              </Box>
            </Box>

            <Box>
              <TextField
                fullWidth
                id="transactionId"
                name="transactionId"
                label="Transaction ID"
                variant="outlined"
                value={formik.values.transactionId}
                onChange={handleTransactionIdChange} // Use the modified function here
                error={
                  formik.touched.transactionId &&
                  Boolean(formik.errors.transactionId)
                }
                helperText={
                  formik.touched.transactionId && formik.errors.transactionId
                }
                sx={{ mb: 2 }}
              />

              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} />
                  ) : isUploaded ? (
                    "Uploaded"
                  ) : (
                    "Save"
                  )}
                </Button>
                <Button type="button" variant="outlined" onClick={handleGoBack}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
        <ToastContainer />
      </Box>
    </Box>
  );
};

export default ReceiptFileUploader;
