import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Tab,
  Tabs,
  Divider,
  Skeleton,
  TableRow,
  TableCell,
  List,
  ListItem,
  ListItemText,
  TablePagination,
  Avatar, TableSortLabel, TableContainer, Table, TableHead, TableBody,
  Modal,
  Backdrop,
  IconButton,
} from "@mui/material";
import { TbSquareRoundedArrowLeftFilled } from "react-icons/tb";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { httpAPI } from "../../../AxiosApi.js";
import { MdCancel, MdVisibility } from "react-icons/md";


import { MdAccountBox } from "react-icons/md";
import moment from "moment";

const PatronDetailsPopup = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [student, setStudent] = useState(null);
  const [donorList, setDonorList] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxImage, setLightboxImage] = useState(null);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');



  const handleSortRequest = (sortBy) => {
    setOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
    // setOrderBy(sortBy)
      const sortedList = [...donorList].sort((a, b) => {
        if (order === 'asc') {
          return a[sortBy] > b[sortBy] ? 1 : -1
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1
        }
      })
      // setDonorList(sortedList)
      setDonorList(sortedList);
      setOrderBy(sortBy);
  }

  useEffect(() => {
    fetchStudentDetails();
    fetchDonationList();
  }, [id]);

  const fetchStudentDetails = async () => {
    try {
      const response = await httpAPI.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getInstitute/${id}`
      );
      if (response.status === 200) {
        setStudent(response.data.response);
      } else {
        console.error("Failed to fetch student details");
      }
    } catch (error) {
      console.error("Error fetching student details:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDonationList = async () => {
    try {
      const response = await httpAPI.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/donorList/${id}`
      );
      if (response.status === 200) {
        setDonorList(response.data.response);
      } else {
        console.error("Failed to fetch donation list");
      }
    } catch (error) {
      console.error("Error fetching donation list:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleGoBack = () => {
    navigate("/dashboard/patron");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const openLightbox = (imageUrl) => {
    setLightboxImage(imageUrl);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: {
          xs: '0',
          sm: '1rem',
          md: '1rem',
        },
        borderRadius: "10px",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          width: '100%',
          padding: "1rem",
          background: "#eeeeee",
          borderRadius: "5px",
          marginBottom: "2rem",
          boxSizing: "border-box",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start", gap: "1rem" }}>
          <span style={{ cursor: "pointer" }} onClick={handleGoBack}>
            <TbSquareRoundedArrowLeftFilled
              style={{ fontSize: "30px", color: "#212529" }}
            />
          </span>
          <Typography variant="h5" style={{ margin: "0" }}>
            Patron Details
          </Typography>
        </Box>
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          width: "100%",
          padding: "1rem",
          background: "#eeeeee",
          borderRadius: "5px",
          marginBottom: "2rem",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start", gap: "1rem" }}>
          <span style={{ cursor: "pointer" }} onClick={handleGoBack}>
            <TbSquareRoundedArrowLeftFilled
              style={{ fontSize: "30px", color: "#212529" }}
            />
          </span>
          <Typography variant="h5" style={{ margin: "0" }}>
            Patron Details
          </Typography>
        </Box>
      </Box> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "auto",
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            sx={{
              background: "#eee",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            <Tab
              label="Personal Details"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#107a0a",
                  color: "#fff",
                  borderRadius: "5px",
                },
              }}
            />
            <Tab
              label="Donation List"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#107a0a",
                  color: "#fff",
                  borderRadius: "5px",
                },
              }}
            />
          </Tabs>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: "100%",
          padding: "1rem",
          background: "#ffffff",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          boxSizing: "border-box",
        }}
      >
        {loading || !student ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={200} height={30} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={200} height={30} />
            </Box>

            <Divider />

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={200} height={30} />
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={200} height={30} />
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={200} height={30} />
            </Box>
          </>
        ) : (
          <>
            {currentTab === 0 && (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'grid', marginBottom: '1rem', gridTemplateColumns: { xs: '1fr', sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr', xl: '1fr 1fr 1fr' }, gap: '1rem' }}>
                  <Box sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: 'column',
                    gap: "5px",
                    // backgroundColor:'#f6f6f6',
                    padding: '5px 10px'
                  }}>
                    <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/${student.profilePicture}`} style={{ width: '150px', cursor: 'pointer' }}
                      onClick={() => openLightbox(`${process.env.REACT_APP_BACKEND_URL}/uploads/${student.profilePicture}`)}
                      alt="Profile"
                    />
                  </Box>
                </Box>
                <Box sx={{ display: 'grid', gridTemplateColumns: { xs: '1fr', sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr', xl: '1fr 1fr 1fr' }, gap: '1rem' }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: 'column',
                      gap: "5px",
                      backgroundColor: '#f6f6f6',
                      padding: '5px 10px'
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Name:
                    </Typography>
                    <Typography variant="body1">{student.fullName}</Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: 'column',
                      gap: "5px",
                      backgroundColor: '#f6f6f6',
                      padding: '5px 10px'
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Email:
                    </Typography>
                    <Typography variant="body1">{student.email}</Typography>
                  </Box>

                </Box>
              </Box>
            )}
            {currentTab === 1 && (
              <>
                {donorList && donorList.length > 0 ? (
                  <>
                    {donorList && donorList.length > 0 ? (
                      <>
                        {/* <List
                          sx={{
                            width: "100%",
                            maxWidth: "100%",
                            bgcolor: "background.paper",
                            display: 'grid',
                            gridTemplateColumns: {
                              xs: 'repeat(1, 1fr)',
                              sm: 'repeat(1, 1fr)',
                              md: 'repeat(2, 1fr)',
                              lg: 'repeat(3, 1fr)',
                              xl: 'repeat(3, 1fr)'
                            },
                            gap: '1rem'
                          }}
                        > */}
                        <TableContainer sx={{ width: '100%' }}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Profile Picture</TableCell>
                                <TableCell>
                                  <TableSortLabel
                                    active={orderBy === 'patronID.fullName'}
                                    direction={orderBy === 'patronID.fullName' ? order : 'asc'}
                                    onClick={() => handleSortRequest('patronID.fullName')}
                                  >
                                    Amount
                                  </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                  <TableSortLabel
                                    active={orderBy === 'scholarshipID.userId.fullName'}
                                    direction={orderBy === 'scholarshipID.userId.fullName' ? order : 'asc'}
                                    onClick={() => handleSortRequest('scholarshipID.userId.fullName')}
                                  >
                                    Recipient Name
                                  </TableSortLabel> 
                                </TableCell>
                                <TableCell>
                                  <TableSortLabel
                                    active={orderBy === 'scholarshipID.userId.email'}
                                    direction={orderBy === 'scholarshipID.userId.email' ? order : 'asc'}
                                    onClick={() => handleSortRequest('scholarshipID.userId.email')}
                                  >
                                    Recipient Email
                                  </TableSortLabel> 
                                </TableCell>
                                {/* <TableCell>
                                  <TableSortLabel
                                    active={orderBy === 'amount'}
                                    direction={orderBy === 'amount' ? order : 'asc'}
                                    onClick={() => handleSortRequest('amount')}
                                  >
                                    Donation Amount
                                  </TableSortLabel>
                                </TableCell > */}
                                <TableCell>
                                  <TableSortLabel
                                    active={orderBy === 'createdAt'}
                                    direction={orderBy === 'createdAt' ? order : 'asc'}
                                    onClick={() => handleSortRequest('createdAt')}
                                  >
                                    Date of Transaction
                                  </TableSortLabel>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {(rowsPerPage > 0
                                ? donorList.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                : donorList
                              ).map((row) => (
                                <TableRow key={row._id}>
                                  <TableCell>
                                    {row?.scholarshipID?.userId?.profilePicture ? (
                                      <img
                                        src={`${process.env.REACT_APP_BACKEND_URL}/uploads/${row?.scholarshipID?.userId?.profilePicture}`}
                                        alt="Profile"
                                        style={{
                                          width: 48,
                                          height: 48,
                                          borderRadius: "50px",
                                          marginRight: "10px",
                                          border: "0.5px solid #efefef",
                                        }}
                                      />
                                    ) : (
                                      <MdAccountBox
                                        style={{
                                          marginRight: "10px",
                                          width: 48,
                                          height: 48,
                                          borderRadius: "5px",
                                          border: "0.5px solid #efefef",
                                          color: "rgb(217 217 217)",
                                        }}
                                      >
                                        {row?.scholarshipID?.userId?.fullName.charAt(0)}
                                      </MdAccountBox>
                                    )}
                                  </TableCell>
                             

                                  <TableCell>
                                    ${row?.amount}
                                  </TableCell>
                                  <TableCell>
                                    {row?.scholarshipID?.userId?.fullName}
                                  </TableCell>
                                  <TableCell>
                                    {row?.scholarshipID?.userId?.email}
                                  </TableCell>
                                  <TableCell>
                                    {moment(row?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>


                        {/* </List> */}

                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={donorList.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </>
                    ) : (
                      // No data found
                      <TableRow>
                        <TableCell colSpan={4} sx={{ textAlign: 'center !important' }}>
                          No data found
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ) : (
                  // No data found
                  <TableRow>
                    <TableCell colSpan={4} sx={{ textAlign: 'center !important' }}>
                      No data found
                    </TableCell>
                  </TableRow>
                )}



              </>
            )}
          </>
        )}
      </Box>
      <Modal
        open={lightboxOpen}
        onClose={closeLightbox}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <IconButton
            onClick={closeLightbox}
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
              color: "#fff",
            }}
          >
            <MdCancel />
          </IconButton>
          <Box
            sx={{
              position: 'relative',
              // background:'#fff',
              width: {
                xs: '90%',
                sm: '90%',
                md: '400px',
                lg: '500px',
                xl: '500px'
              }
              , height: {
                xs: '90%',
                sm: '90%',
                md: '400px',
                lg: '500px',
                xl: '500px'
              }

            }}>

            <img src={lightboxImage} alt="Lightbox" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'contain', zIndex: "10" }} />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default PatronDetailsPopup;
