import React, { useEffect, useState } from "react";
import { httpAPI } from "../../AxiosApi.js";
import { MdEdit, MdDelete, MdVisibility } from "react-icons/md";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  TablePagination,
  TableSortLabel,
  Tabs,
  Tab,
  Box,
  Skeleton,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Popover,
  Menu,
  MenuItem,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { AiFillEdit } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import { FaArrowUp } from "react-icons/fa";
import moment from 'moment';
import { useAuth } from "../Utils/AuthContext.js";
import { HiDotsVertical } from "react-icons/hi";


export default function Institute() {
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("fullName");
  const [order, setOrder] = useState("asc");
  const [isStatusChanging, setStatusChanging] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const [loading, setLoading] = useState(true);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedInstituteId, setSelectedInstituteId] = useState(null);
  const [statusChangeConfirmationOpen, setStatusChangeConfirmationOpen] = useState(false);
  const [statusChangeActive, setStatusChangeActive] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleClick = (event , ) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClick = (event, instituteId) => {
    setAnchorEl(event.currentTarget); 
    setSelectedInstituteId(instituteId);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { logout } = useAuth();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await httpAPI.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/instituteDetails`
      );
      if (response.data && response.data.response) {
        setData(response.data.response);
      } else {
        console.error("Invalid data structure in the response:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response) {
        if (error.response.status === 401) {
          toast.error(error.response.data.message);
          logout();
          navigate("/");
        } else {
          toast.error("An error occurred:", error.message);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggleStatus = async (userId, isActive) => {
    setStatusChangeConfirmationOpen(true);
    setStatusChangeActive(!isActive);
    setSelectedInstituteId(userId);
  };

  const confirmStatusChange = async () => {
    setStatusChanging(true);
    try {
      await httpAPI.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/checkIsActive/${selectedInstituteId}`
      );
      fetchData();
      if (statusChangeActive) {
        toast.success("Status changed to inactive.");
      } else {
        toast.success("Status changed to active.");
      }
    } catch (error) {
      console.error("Error toggling isActive:", error);
    } finally {
      setStatusChanging(false);
      setStatusChangeConfirmationOpen(false);
    }
  };

  const handleDelete = async (e) => {
    try {
      const response = await httpAPI.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/deleteInstitute/${selectedInstituteId}`
      );
      if (response.status === 200) {
        toast.success("Institute Deleted Successfully");
        fetchData();
      }
      setDeleteConfirmationOpen(false);
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const filterByValue = (object, searchValue) => {
    return Object.values(object).some((value) => {
      if (typeof value === 'object' && value !== null) {
        return filterByValue(value, searchValue);
      }
      return value.toString().toLowerCase().includes(searchValue.toLowerCase());
    });
  };

  const filteredData = data.filter((student) => filterByValue(student, globalFilter));

  const sortedData = stableSort(filteredData, getComparator(order, orderBy));


  let displayedStudents;
  if (activeTab === "active") {
    displayedStudents = sortedData.filter((student) => student.isActive);
  } else if (activeTab === "inactive") {
    displayedStudents = sortedData.filter((student) => !student.isActive);
  } else {
    displayedStudents = sortedData;
  }

  const activeStudentsCount = data.filter((student) => student.isActive).length;
  const inactiveStudentsCount = data.filter((student) => !student.isActive).length;


  return (
    <div className="student">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isStatusChanging}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className="btnHeader" >

        <h1 style={{ textAlign: 'left' }}>Institute Listing</h1>

        <NavLink

          to="/dashboard/addInstitute"
          style={{ width: 'auto', textDecoration: "none", color: "inherit", marginTop: "20px" }}
        >
          <Button
            sx={{
              backgroundColor: "#107a0a !important",
              transition: "background-color 0.3s ease",
              textTransform: "capitalize",
              fontSize: "16px",
              boxShadow: "rgb(76 175 80 / 45%) 0px 5px 20px 0px",
              width: {
                xs: '100%',
                sm: '100%',
                md: 'auto'
              },
              "&:hover": {
                background: '#065d01 !important'
              }
            }}
          >
            Add Institute
          </Button>
        </NavLink>


      </Box>

      <Box style={{ width: "100%",borderRadius: '10px' }}>
        <Box
          sx={{
            // borderBottom: 1,
            borderColor: "divider",
            // mb: "0.5rem", 
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: '10px 15px',
            boxSizing:'border-box'
          }}
        >

          <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            variant="fullWidth"
            aria-label="Active and Inactive Tabs"
            sx={{ background: "#fff", padding: "5px", borderRadius: "0px", width: {xs:'100%', sm:'100%', md:'100%', lg:'50%', xl:'50%'}  }}
            
          >
            <Tab
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                  <span style={{ paddingRight: '10px', fontWeight: "bold" }}>

                    All{" "}
                  </span>
                  <span style={{ backgroundColor: "#212B36", color: "#fff", padding: "5px 10px", borderRadius: "5px" }}>
                    {data.length}
                  </span>
                </Box>
              }
              value="all"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#eaeaea",
                  color: "#000",
                  borderRadius: "5px",
                },
              }}
            />
            <Tab
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                  <span style={{ paddingRight: '10px', fontWeight: "bold" }}>

                    Active{" "}
                  </span>
                  <span style={{ backgroundColor: "#22C55E29", color: "#118D57", padding: "5px 10px", borderRadius: "5px", fontWeight: 900 }}>
                    {activeStudentsCount}
                  </span>
                </Box>
              }
              value="active"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#eaeaea",
                  color: "#000",
                  borderRadius: "5px",
                },
              }}
            />
            <Tab
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                  <span style={{ paddingRight: '10px', fontWeight: "bold" }}>

                    Inactive{" "}
                  </span>
                  <span style={{ backgroundColor: "#FF563029", color: "#B71D18", padding: "5px 10px", borderRadius: "5px", fontWeight: 900 }}>
                    {inactiveStudentsCount}
                  </span>
                </Box>
              }
              // label={`Inactive (${inactiveStudentsCount})`}
              value="inactive"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#eaeaea",
                  color: "#000",
                  borderRadius: "5px",
                },
              }}
            />

          </Tabs>






          {/* <div className="btnHeader"> */}
          <Box className="search" style={{ display: 'flex', justifyContent: 'flex-end' }}>

            <TextField
              value={globalFilter}
              // style={{ width: '100%' }}
              type="search"
              placeholder="Search by Name"
              onChange={(e) => setGlobalFilter(e.target.value)}
              label="Search"
            />
          </Box>
          {/* </div> */}

        </Box>

        <TableContainer>
          <Table>
            
            <TableHead>
              <TableRow>
                {/* <TableCell>Image</TableCell> */}
                <TableCell >
                  <TableSortLabel
                    active={orderBy === "fullName"}
                    direction={orderBy === "fullName" ? order : "asc"}
                    onClick={() => handleRequestSort("fullName")}
                    style={{ paddingLeft: '10px' }}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                {/* <TableCell>
                <TableSortLabel
                  active={orderBy === "email"}
                  direction={orderBy === "email" ? order : "asc"}
                  onClick={() => handleRequestSort("email")}
                >
                  Email
                </TableSortLabel>
              </TableCell> */}
                <TableCell >
                  <TableSortLabel
                    active={orderBy === "phone.phoneNumber"}
                    direction={orderBy === "phone.phoneNumber" ? order : "asc"}
                    onClick={() => handleRequestSort("phone.phoneNumber")}
                  >
                    Phone
                  </TableSortLabel>
                </TableCell>
                {/* <TableCell>
                <TableSortLabel
                  active={orderBy === "dateOfEstablishment"}
                  direction={
                    orderBy === "dateOfEstablishment" ? order : "asc"
                  }
                  onClick={() => handleRequestSort("dateOfEstablishment")}
                >
                  Date of Establishment
                </TableSortLabel>
              </TableCell> */}
                <TableCell >
                  <TableSortLabel
                    active={orderBy === "address.city"}
                    direction={orderBy === "address.city" ? order : "asc"}
                    onClick={() => handleRequestSort("address.city")}
                  >
                    Address
                  </TableSortLabel>
                </TableCell>
                {/* <TableCell>
                <TableSortLabel
                  active={orderBy === "bankDetails.ownerName"}
                  direction={
                    orderBy === "bankDetails.ownerName" ? order : "asc"
                  }
                  onClick={() => handleRequestSort("bankDetails.ownerName")}
                >
                  Account Holder Name
                </TableSortLabel>
              </TableCell> */}
                <TableCell >
                  <TableSortLabel
                    active={orderBy === "dateOfEstablishment"}
                    direction={
                      orderBy === "dateOfEstablishment" ? order : "asc"
                    }
                    onClick={() => handleRequestSort("dateOfEstablishment")}
                  >
                    Date Of Establishment
                  </TableSortLabel>
                </TableCell>

                <TableCell >Active/ Inactive</TableCell>
                <TableCell >Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                Array.from({ length: 8 }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Skeleton animation="wave" width={"100%"} />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" width={"100%"} />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" width={"100%"} />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" width={"100%"} />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" width={"100%"} />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" width={"100%"} />
                    </TableCell>
                    {/* <TableCell>
                      <Skeleton animation="wave" width={"100%"} />
                    </TableCell> */}
                    {/* <TableCell>
                    <Skeleton animation="wave" width={"100%"} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} />
                  </TableCell> */}
                    {/* <TableCell>
                    <Skeleton animation="wave" width={"100%"} />
                  </TableCell> */}
                  </TableRow>
                ))
              ) : displayedStudents.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={10} align="center" sx={{ textAlign: 'center !important' }}>
                    No data found
                  </TableCell>
                </TableRow>
              ) : (
                displayedStudents
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row._id}>
                      <TableCell>

                        <NavLink style={{ display: 'flex', gap: "10px", color: 'black', textDecoration: 'none' }} to={`/dashboard/viewInstitute/${row._id}`}>

                          {row.profilePicture ? (
                            <img
                            src={(row.profilePicture && !row.profilePicture.endsWith('/null'))
                            ? row.profilePicture 
                            : "/images/dummyImage.png"}
                              alt="institue-img"
                              // style={{ width: 40 }}
                              style={{ borderRadius: '50%', width: 40, height: 40 }}
                            // onError={(e) => (e.target.src = "dummy-image-url")}
                            />
                          ) : ""}
                          <span>
                            <span style={{ fontWeight: 600 }}>
                              {row.fullName.length > 10 ? row.fullName.substring(0, 15) + "..." : row.fullName}
                            </span>
                            <br />
                            {row.email.length > 20 ? row.email.substring(0, 25) + "..." : row.email}
                          </span>
                        </NavLink>
                      </TableCell>

                   
                      <TableCell>
                        {`${row.phone.countryCode}-${row.fullName.length > 10 ? row.phone.phoneNumber.substring(0, 10) + "..." : row.phone.phoneNumber}`}
                      </TableCell>
                      <TableCell>{row.address.city.length > 20 ? row.address.city.substring(0, 20) + "..." : row.address.city}</TableCell>
                  

                      <TableCell>
                        {row.dateOfEstablishment ? moment(row.dateOfEstablishment).format('MMMM Do YYYY') : "NA"}
                      </TableCell>

                      <TableCell>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={row.isActive}
                              onChange={() => toggleStatus(row._id, row.isActive)}
                            />
                          }
                        />

                        
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "12px",
                          }}
                        >
                          <NavLink to={`/dashboard/viewInstitute/${row._id}`}>
                            <MdVisibility
                              style={{ fontSize: "20px", color: "#000" }}
                            />
                          </NavLink>


                          <Button sx={{ color: '#000', padding: 0, margin: 0, fontSize: "16px" }}   onClick={(e) => handleClick(e, row._id)}>
                            <HiDotsVertical />
                          </Button>

                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose }
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          >
                              <MenuItem sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }} onClick={handleClose}>
                                <NavLink style={{ textTransform:'capitalize', fontSize:'16px', color: 'black', textDecoration: 'none', display:'flex', alignItems:'center', gap:'0.5rem' }} 
                                to={`/dashboard/editInstitute/${selectedInstituteId}`}
                                >

                                <MdEdit style={{ fontSize:'20px' }}/>
                                Edit  
                                </NavLink>
                              </MenuItem>
                            

                            <MenuItem sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }} onClick={handleClose}>
                              <Button
                                sx={{ textTransform:'capitalize', fontSize:'16px', color: "#ff0000", display:'flex', alignItems:'center', gap:'0.5rem', padding:'0', minWidth:'unset' }}
                                onClick={() => {
                                  setSelectedInstituteId(selectedInstituteId);
                                  setDeleteConfirmationOpen(true);
                                }}>
                                <MdDelete style={{ fontSize:'20px' }} />
                                Delete
                              </Button>
                            </MenuItem>
                          </Menu>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

      </Box>



      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={displayedStudents.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ToastContainer />

      <Dialog
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this institute?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
      
      <Dialog
        open={statusChangeConfirmationOpen}
        onClose={() => setStatusChangeConfirmationOpen(false)}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          Are you sure you want to change the status of this institute?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setStatusChangeConfirmationOpen(false)}>
            Cancel
          </Button>
          <Button onClick={confirmStatusChange}>Change Status</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}
function descendingComparator(a, b, orderBy) {
  if (orderBy.includes(".")) {
    const keys = orderBy.split(".");
    const aValue = getNestedPropertyValue(a, keys);
    const bValue = getNestedPropertyValue(b, keys);
    if (bValue < aValue) {
      return -1;
    }
    if (bValue > aValue) {
      return 1;
    }
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function getNestedPropertyValue(obj, keys) {
  return keys.reduce((accumulator, currentKey) => {
    return accumulator && accumulator[currentKey] ? accumulator[currentKey] : null;
  }, obj);
}
