import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { httpAPI } from "../../AxiosApi.js";

import "./Style/ManageScholarship.css";
export default function ManageScholarShip() {
  const { scholarshipId } = useParams();
  const [data, setData] = useState();
  const [currentTab, setCurrentTab] = useState("scholarship");

  const fetchData = async (pageIndex = 0, pageSize = 10) => {
    try {
      const response = await httpAPI.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/scholrship/${scholarshipId}`,
        {
          params: { pageIndex, pageSize },
        }
      );
      if (response.data && response.data.response) {
        setData(response.data.response);
      } else {
        console.error("Invalid data structure in the response:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handelTab = (e) => {
    const current = e.currentTarget.id;
    console.log(current);
    if (current == 1) {
      setCurrentTab("scholarship");
    } else if (current == 2) {
      setCurrentTab("patron");
    } else {
      setCurrentTab("user");
    }
    console.log("currentTab, ", currentTab);
  };
  return (
    <>
      <div className="manageScholarship">
        <div className="navBar">
          <ul>
            <li id="1" onClick={handelTab}>
              Scholarship
            </li>
            <li id="2" onClick={handelTab}>
              Patron Details
            </li>
            <li id="3" onClick={handelTab}>
              User Detail
            </li>
          </ul>
        </div>
        {currentTab == "scholarship" ? (
          <div className="schoLarShip">
            <h2>Scholarship Detail</h2>
            <div className="showList">
              <ul>
                <li>Title</li>
                <li>Description</li>
                <li>Purpose</li>
                <li>Course Name</li>
                <li>Start Date</li>
                <li>Target Date</li>
                <li>Target Amount</li>
              </ul>
              <ul>
                <li>{data?.title}</li>
                <li>{data?.description}</li>
                <li>{data?.purpose}</li>
                <li>{data?.courseName}</li>
                <li>{data?.startDate?.substring(0, 10)}</li>
                <li>{data?.endDate?.substring(0, 10)}</li>
                <li>${data?.targetAmount}</li>
              </ul>
            </div>
          </div>
        ) : (
          ""
        )}
        {currentTab == "user" ? (
          <div className="userDetail">
            <ul>
              <li>Full Name</li>
              <li>Email</li>
              <li>Phone</li>
              <li>Date Of Birth</li>
            </ul>
            <ul>
              <li>{data.userId.fullName}</li>
              <li>{data.userId.email}</li>
              <li>
                {data.userId.phone.countryCode}-{data.userId.phone.phoneNumber}
              </li>
              <li>{data.userId.dateOfBirth}</li>
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
