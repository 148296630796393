import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { countryCode } from "./CountryCode.js";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import { httpAPI } from "../../AxiosApi.js";
import { ToastContainer, toast } from "react-toastify";
import { Box, Button, CircularProgress, InputLabel, TextField, Tab, Tabs, Select, MenuItem, FormControl, Modal, Autocomplete } from "@mui/material";
import { BsFillImageFill } from "react-icons/bs";
import { RiFolderUploadLine } from "react-icons/ri";
import { TbSquareRoundedArrowLeftFilled } from "react-icons/tb";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Country, State, City } from 'country-state-city';

const StyledCropper = styled(Cropper)(({ imageWidth }) => ({
  height: 400,
  width: imageWidth ? imageWidth : 'auto',
}));

const ModalContainer = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ModalContent = styled(Box)({
  backgroundColor: 'white',
  border: '0px solid #000',
  boxShadow: 24,
  padding: 4,
});

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const validationSchemaStep1 = Yup.object().shape({
  fullName: Yup.string().trim().matches(/^[A-Za-z\s]+$/, 'Institute Name should contain only alphabets').required("Institute Name is required").notOneOf([" "], "Institute Name cannot be empty or contain only spaces"),
  email: Yup.string().email('Invalid email').required('Email is required').test(
    'is-public-provider',
    'Public email providers are not allowed',
    value => {
      const publicProviders = [
        'gmail.com',
        'yahoo.com',
        'outlook.com',
        'hotmail.com',
        'yopmail.com'
      ];

      const domain = value.split('@')[1];
      return !publicProviders.includes(domain);
    }
  ),
  phoneNumber: Yup.string()
    .required("Phone Number is required")
    .matches(/^[0-9]{9,16}$/, "Invalid phone number"),
  dateOfEstablishment: Yup.string().required("Date of Establishment is required"),
  countryCode: Yup.string().required("Country Code is required"),
});

const validationSchemaStep2 = Yup.object().shape({
  street: Yup.string().required("Street is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  pincode: Yup.string()
    .required("Pincode is required")
    .min(5, "Pincode must be at least 5 characters")
    .max(9, "Pincode must be at most 9 characters"),
  country: Yup.string().required("Country is required"),
});

const validationSchemaStep3 = Yup.object().shape({
  bankName: Yup.string().required("Bank Name is required").matches(/^[a-zA-Z\s]+$/, "Bank Name must contain only characters"),
  accountNumber: Yup.string()
    .test("is-required", "Account Number is required", function (value) {
      return this.parent.accountNumber || value !== undefined;
    })
    .matches(/^[0-9]+$/, "Account Number must contain only numeric values"),
  confirmAccountNumber: Yup.string()
    .required("Confirm Account Number is required")
    .oneOf([Yup.ref("accountNumber"), null], "Account Numbers must match"),
  bankCode: Yup.string().required("Routing Number is required").matches(/^\d{9}$/, "Routing number must be 9 digits"),
  ownerName: Yup.string()
    .required("Account Holder Name is required")
    .matches(/^[a-zA-Z\s]+$/, "Account Holder Name must contain only characters"),
  instituteProfilePicture: Yup.mixed()
    .required("Profile Picture is required")
    .test("fileSize", "File size too large", (value) => value && value.size <= 1024 * 1024 * 10) // 1MB
    .test("fileType", "Unsupported file format", (value) => value && ["image/jpeg", "image/png"].includes(value.type)),
});



const AddInstituteForm = () => {
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorsStep1, setErrorsStep1] = useState({});
  const [errorsStep2, setErrorsStep2] = useState({});
  const [errorsStep3, setErrorsStep3] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split("T")[0];
  const [imagePreview, setImagePreview] = useState(null);
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [cropper, setCropper] = useState();

  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  useEffect(() => {
    const countries = Country.getAllCountries();
    setCountryData(countries);
  }, []);

  const handleCountryChange = (event) => {
    const countryCode = event.target.value;
    const selectedCountry = countryData.find(country => country.isoCode === countryCode);
    setSelectedCountry(countryCode);
    const states = State.getStatesOfCountry(countryCode);
    setStateData(states);
    formik.setFieldValue('country', selectedCountry.name);
    formik.setFieldValue('state', '');
    formik.setFieldValue('city', '');
  };

  const handleStateChange = (event) => {
    const stateCode = event.target.value;
    const selectedState = stateData.find(state => state.isoCode === stateCode);
    setSelectedState(stateCode);
    formik.setFieldValue('state', selectedState.name);
    formik.setFieldValue('city', '');
    const cities = City.getCitiesOfState(selectedCountry, stateCode);
    setCityData(cities);
  };

  const handleCityChange = (event) => {
    const cityCode = event.target.value;
    setSelectedCity(cityCode);
    formik.setFieldValue('city', cityCode);
  };

  const getCroppedImage = () => {
    if (typeof cropper !== "undefined") {
      const canvas = cropper.getCroppedCanvas();
      canvas.toBlob((blob) => {
        const croppedFile = new File([blob], "cropped-image.jpg", {
          type: "image/jpeg",
          lastModified: new Date().getTime(),
        });
        setCroppedImage(croppedFile);
        setIsModalOpen(false);
        // Set the cropped image preview
        setImagePreview(URL.createObjectURL(croppedFile));
        console.log("-==-=-=-=-=-=-=", imagePreview)
      }, "image/jpeg");
    }
  };

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phoneNumber: "",
      dateOfEstablishment: "",
      street: "",
      city: "",
      state: "",
      pincode: "",
      country: "",
      bankName: "",
      accountNumber: "",
      bankCode: "",
      ownerName: "",
      instituteProfilePicture: null,
      countryCode: "",
    },
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);

        switch (step) {
          case 1:
            await validationSchemaStep1.validate(values, { abortEarly: false });
            setStep(step + 1);
            break;
          case 2:
            await validationSchemaStep2.validate(values, { abortEarly: false });
            setStep(step + 1);
            break;
          case 3:
            await validationSchemaStep3.validate(values, { abortEarly: false });
            try {
              setIsSubmitting(true);
              const formDataToSend = new FormData();
              for (const key in values) {
                if (key === 'instituteProfilePicture' && croppedImage) {
                  formDataToSend.append(key, croppedImage);
                } else {
                  formDataToSend.append(key, values[key]);
                }
              }
              const response = await httpAPI.post(
                `${process.env.REACT_APP_BACKEND_URL}/admin/addInstitute`,
                formDataToSend,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: "Bearer your_access_token",
                  },
                }
              );
              if (response.status === 200) {
                toast.success("Successfully Added");
                setIsSubmitting(false);
                formik.resetForm();
                navigate("/dashboard/institute");
              }
            } catch (error) {
              console.error("Error fetching data:", error);
              setIsSubmitting(false);
            }
            setIsSubmitting(false);
            break;
          default:
            setIsSubmitting(false);
            break;
        }
      } catch (error) {
        setIsSubmitting(false);
        if (step === 1) {
          setErrorsStep1(error.inner.reduce((acc, err) => {
            acc[err.path] = err.message;
            return acc;
          }, {}));
        } else if (step === 2) {
          setErrorsStep2(error.inner.reduce((acc, err) => {
            acc[err.path] = err.message;
            return acc;
          }, {}));
        } else if (step === 3) {
          setErrorsStep3(error.inner.reduce((acc, err) => {
            acc[err.path] = err.message;
            return acc;
          }, {}));
        }
      }
    },
  });

  const handleNextStep = async () => {
    if (step === 1) {
      try {
        await validationSchemaStep1.validate(formik.values, { abortEarly: false });
        setErrorsStep1({});
        setStep(step + 1);
        setTabIndex(1);
      } catch (error) {
        setErrorsStep1(error.inner.reduce((acc, err) => {
          acc[err.path] = err.message;
          return acc;
        }, {}));
      }
    } else if (step === 2) {
      try {
        await validationSchemaStep2.validate(formik.values, { abortEarly: false });
        setErrorsStep2({});
        setStep(step + 1);
        setTabIndex(2);
      } catch (error) {
        setErrorsStep2(error.inner.reduce((acc, err) => {
          acc[err.path] = err.message;
          return acc;
        }, {}));
      }
    } else if (step === 3) {
      validationSchemaStep3.validate(formik.values, { abortEarly: false })
        .then(() => {
          setErrorsStep3({});
          setIsSubmitting(true);
          formik.handleSubmit();
        })
        .catch(error => {
          setErrorsStep3(error.inner.reduce((acc, err) => {
            acc[err.path] = err.message;
            return acc;
          }, {}));
        });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (value.match(/^\s+/)) {
      formik.setFieldValue(name, value.trimLeft());
    } else {
      formik.setFieldValue(name, value);
    }
  };
  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      formik.setFieldValue("instituteProfilePicture", file);
      setImagePreview(URL.createObjectURL(file));
      setIsModalOpen(true);
    } else {
      formik.setFieldValue("instituteProfilePicture", null);
      setImagePreview(null);
    }
  };
  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
    setStep(newIndex + 1)
  };
  const handleGoBack = () => {
    navigate("/dashboard/institute");
  };
  const handlePrevious = () => {
    if (step > 1) {
      setStep(step - 1)
      setTabIndex(step - 2)
    }
  };
  return (
    <Box className="addInstitute">
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          width: {
            xs:'auto',
            sm:'auto',
            md:'auto',
            lg:'auto',
            xl:'auto'
          },
          padding: "1rem",
          background: "#eeeeee",
          borderRadius: "5px",
          marginBottom: "2rem",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start", gap: "1rem" }}>
          <span style={{ cursor: "pointer" }} onClick={handleGoBack}>
            <TbSquareRoundedArrowLeftFilled
              style={{ fontSize: "30px", color: "#212529" }}
            />
          </span>
          <h2 style={{ margin: "0" }}>Add Institute</h2>
        </Box>
      </Box>

      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: {
              xs:'center',
              sm:'flex-start',
              md:'flex-start'
            },
            flexDirection: "column",
          }}
        >
          <InputLabel htmlFor="profilePicture">
            Institute Photo Preview
          </InputLabel>
          <Box className=" institute-img-div">

            {croppedImage ? (
              <Box>
                <img src={imagePreview} alt="Cropped Image" />
              </Box>
            ) : (
              <BsFillImageFill style={{ fontSize: "100px", color: "gray" }} />

            )}
          </Box>
          <Button
            component="label"
            variant="contained"
            startIcon={<RiFolderUploadLine />}
            sx={{ height: '45px' }}
          >
            Upload Profile Photo
            <input
              id="profilePicture"
              type="file"
              name="instituteProfilePicture"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
              error={
                formik.touched.instituteProfilePicture &&
                Boolean(formik.errors.instituteProfilePicture)
              }
            />
          </Button>
          <span style={{ color: 'red' }}>{errorsStep3.instituteProfilePicture}</span>
        </Box>
        <ModalContainer
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <ModalContent sx={{ width: { xs: '300px', sm: '450px', md: '500px', lg: '500px', xl: '500px' } }}>
            <StyledCropper
              imageWidth={imagePreview ? '100%' : 'auto'}
              aspectRatio={16 / 9}
              src={imagePreview}
              guides={false}
              crop={(e) => setCroppedImage(e.detail)}
              zoomable={false}
              viewMode={1}
              dragMode="move"
              scalable={false}
              cropBoxMovable={true}
              cropBoxResizable={true}
              minCropBoxWidth={10}
              minCropBoxHeight={10}
              onInitialized={(instance) => setCropper(instance)}
            />
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem', marginBottom: '0.5rem' }}>
              <Button onClick={getCroppedImage} component="label" variant="contained" sx={{ height: '45px' }}>Crop Image</Button>
            </Box>
          </ModalContent>
        </ModalContainer>

        <Box sx={{ width: "100%", margin: "2rem 0" }}>
          <Box sx={{ width: "auto" }}>
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              value={tabIndex}
              // onChange={handleTabChange} 
              sx={{
                background: "#eee",
                padding: "5px",
                borderRadius: "5px",
                // width: "fit-content",
                
              }}
            >
              <Tab
                label="Profile Information"
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#107a0a",
                    color: "#fff",
                    borderRadius: "5px",
                  },
                }}
              />
              <Tab
                label="Address Information"
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#107a0a",
                    color: "#fff",
                    borderRadius: "5px",
                  },
                }}
              />
              <Tab
                label="Bank Details"
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#107a0a",
                    color: "#fff",
                    borderRadius: "5px",
                  },
                }}
              />
            </Tabs>
          </Box>

          <form onSubmit={formik.handleSubmit} style={{ margin: "1rem 0" }}>
            {step === 1 && (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
                  flexDirection: "column",
                  gap: "1rem",
                  width: "100%",
                }}
              >
                <Box>
                  <TextField
                    fullWidth
                    type="text"
                    name="fullName"
                    label="Institute Name"
                    value={formik.values.fullName}
                    onChange={handleInputChange}
                    error={
                      formik.touched.fullName && Boolean(formik.errors.fullName)
                    }
                    helperText={formik.touched.fullName && formik.errors.fullName}
                  />
                  <span style={{ color: 'red' }}>{errorsStep1.fullName}</span>
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    type="email"
                    name="email"
                    label="Institute Email"
                    value={formik.values.email}
                    onChange={handleInputChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                  <span style={{ color: 'red' }}>{errorsStep1.email}</span>
                </Box>
                <Box>
                  <Box sx={{ width: "100%" }}>
                    <FormControl fullWidth>
                      {/* <InputLabel id="countryCodeLabel">Country Code</InputLabel> */}
                      <Autocomplete
                        options={countryCode}
                        getOptionLabel={(option) => `${option.flag} ${option.name} (${option.dial_code})`}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            formik.handleChange({
                              target: {
                                name: "countryCode",
                                value: newValue.dial_code
                              }
                            });
                          }
                        }}
                        value={countryCode.find(option => option.dial_code === formik.values.countryCode) || null}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Country Code"
                            error={
                              formik.touched.countryCode &&
                              Boolean(formik.errors.countryCode)
                            }
                            helperText={
                              formik.touched.countryCode && formik.errors.countryCode
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </Box>
                  <span style={{ color: 'red' }}>{errorsStep1.countryCode}</span>
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    type="tel"
                    name="phoneNumber"
                    label="Phone Number"
                    value={formik.values.phoneNumber}
                    onChange={handleInputChange}
                    error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                    helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                  />
                  <span style={{ color: 'red' }}>{errorsStep1.phoneNumber}</span>
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    type="date"
                    name="dateOfEstablishment"
                    label="Date Of Establishment"
                    value={formik.values.dateOfEstablishment}
                    onChange={handleInputChange}
                    error={
                      formik.touched.dateOfEstablishment &&
                      Boolean(formik.errors.dateOfEstablishment)
                    }
                    helperText={
                      formik.touched.dateOfEstablishment &&
                      formik.errors.dateOfEstablishment
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: formattedDate,
                    }}
                  />
                  <span style={{ color: 'red' }}>{errorsStep1.dateOfEstablishment}</span>
                </Box>


              </Box>
            )}
            {step === 2 && (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
                  flexDirection: "column",
                  gap: "1rem",
                  width: "100%",
                }}
              >
                <Box>
                  <FormControl fullWidth>
                    {/* <InputLabel id="country-label">Country</InputLabel> */}
                    <Autocomplete
                      options={countryData}
                      getOptionLabel={(option) => `${option.flag} ${option.name}`}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          handleCountryChange({ target: { value: newValue.isoCode } });
                        }
                      }}
                      value={countryData.find(option => option.isoCode === selectedCountry) || null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Country"
                          error={Boolean(formik.touched.country && formik.errors.country)}
                          helperText={formik.touched.country && formik.errors.country}
                        />
                      )}
                    />
                    <span style={{ color: 'red' }}>{errorsStep2.country}</span>
                  </FormControl>
                </Box>

                <Box>
                  <FormControl fullWidth>
                    {/* <InputLabel id="state-label">State</InputLabel> */}
                    <Autocomplete
                      options={stateData}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          handleStateChange({ target: { value: newValue.isoCode } });
                        }
                      }}
                      value={stateData.find(option => option.isoCode === selectedState) || null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="State"
                          error={Boolean(formik.touched.state && formik.errors.state)}
                          helperText={formik.touched.state && formik.errors.state}
                        />
                      )}
                    />
                    <span style={{ color: 'red' }}>{errorsStep2.state}</span>
                  </FormControl>
                </Box>

                <Box>
                  <FormControl fullWidth>
                    {/* <InputLabel id="city-label">City</InputLabel> */}
                    <Autocomplete
                      options={cityData}  
                      getOptionLabel={(option) => option.name}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          handleCityChange({ target: { value: newValue.name } });
                        }
                      }}
                      value={cityData.find(option => option.name === selectedCity) || null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="City"
                          error={Boolean(formik.touched.city && formik.errors.city)}
                          helperText={formik.touched.city && formik.errors.city}
                        />
                      )}
                    />
                    <span style={{ color: 'red' }}>{errorsStep2.city}</span>
                  </FormControl>
                </Box>

                <Box>
                  <TextField
                    fullWidth
                    type="text"
                    name="street"
                    label="Street"
                    value={formik.values.street}
                    onChange={handleInputChange}
                    error={formik.touched.street && Boolean(formik.errors.street)}
                    helperText={formik.touched.street && formik.errors.street}
                  />
                  <span style={{ color: 'red' }}>{errorsStep2.street}</span>
                </Box>

                <Box>

                  <TextField
                    fullWidth
                    type="text"
                    name="pincode"
                    label="Pincode"
                    value={formik.values.pincode}
                    onChange={handleInputChange}
                    error={
                      formik.touched.pincode && Boolean(formik.errors.pincode)
                    }
                    helperText={formik.touched.pincode && formik.errors.pincode}
                  />
                  <span style={{ color: 'red' }}>{errorsStep2.pincode}</span>
                </Box>

              </Box>
            )}
            {step === 3 && (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
                  flexDirection: "column",
                  gap: "1rem",
                  width: "100%",
                }}
              >
                <Box>

                  <TextField
                    fullWidth
                    type="text"
                    name="bankName"
                    label="Bank Name"
                    value={formik.values.bankName}
                    onChange={handleInputChange}
                    error={
                      formik.touched.bankName && Boolean(formik.errors.bankName)
                    }
                    helperText={formik.touched.bankName && formik.errors.bankName}
                  />
                  <span style={{ color: 'red' }}>{errorsStep3.bankName}</span>
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    type="text"
                    name="accountNumber"
                    label="Account Number"
                    value={formik.values.accountNumber}
                    onChange={handleInputChange}
                    error={
                      formik.touched.accountNumber &&
                      Boolean(formik.errors.accountNumber)
                    }
                    helperText={
                      formik.touched.accountNumber &&
                      formik.errors.accountNumber
                    }
                  />
                  <span style={{ color: 'red' }}>{errorsStep3.accountNumber}</span>
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    type="text"
                    name="confirmAccountNumber"
                    label="Confirm Account Number"
                    value={formik.values.confirmAccountNumber}
                    onChange={handleInputChange}
                    error={
                      formik.touched.confirmAccountNumber &&
                      Boolean(formik.errors.confirmAccountNumber)
                    }
                    helperText={
                      formik.touched.confirmAccountNumber &&
                      formik.errors.confirmAccountNumber
                    }
                  />
                  <span style={{ color: 'red' }}>{errorsStep3.confirmAccountNumber}</span>

                </Box>

                <Box>

                  <TextField
                    fullWidth
                    type="text"
                    name="bankCode"
                    label="Routing Number"
                    value={formik.values.bankCode}
                    onChange={handleInputChange}
                    error={
                      formik.touched.bankCode && Boolean(formik.errors.bankCode)
                    }
                    helperText={formik.touched.bankCode && formik.errors.bankCode}
                  />
                  <span style={{ color: 'red' }}>{errorsStep3.bankCode}</span>
                </Box>
                <Box>

                  <TextField
                    fullWidth
                    type="text"
                    name="ownerName"
                    label="Account Holder Name"
                    value={formik.values.ownerName}
                    onChange={handleInputChange}
                    error={
                      formik.touched.ownerName && Boolean(formik.errors.ownerName)
                    }
                    helperText={
                      formik.touched.ownerName && formik.errors.ownerName
                    }
                  />
                  <span style={{ color: 'red' }}>{errorsStep3.ownerName}</span>
                </Box>
              </Box>
            )}
          </form>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", margin: "2rem 0" }}>
          <Button
            variant="contained"
            sx={{ marginRight: "10px" }}
            onClick={handleGoBack}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ marginRight: "10px" }}
            onClick={handlePrevious}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            onClick={handleNextStep}
            disabled={isSubmitting}
            endIcon={
              isSubmitting && (
                <CircularProgress
                  size={20}
                  style={{ color: "white" }}
                  thickness={6}
                />
              )
            }
          >
            {step === 3 ? "Submit" : "Next"}
          </Button>
        </Box>
      </Box>
      <ToastContainer />
    </Box>
  );
};

export default AddInstituteForm;
