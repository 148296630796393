import React, { useContext, useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Button, TextField, Typography } from "@mui/material";
import { httpAPI } from "../../AxiosApi.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../Utils/UserContext.js";

const UpdateProfile = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { user, setUser } = useContext(UserContext);

  const profilePicture = user?.profilePicture;
  const name = user?.fullName;
  const email = user?.email;
  const [selectedImage, setSelectedImage] = useState(profilePicture);

  // Validation Schema
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full Name Is Required"),
    profilePicture: Yup.mixed().test(
      "fileType",
      "Only PNG, JPG, and JPEG formats are allowed",
      (value) => {
        if (!value) return true;
        return (
          ["image/png", "image/jpeg", "image/jpg"].includes(value.type) &&
          value.size <= 10485760
        );
      }
    ),
  });

  const formik = useFormik({
    initialValues: {
      fullName: name || "",
      profilePicture: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);
        const formData = new FormData();
        console.log("-=-===--=-=-=nam,e", values.fullName)
        formData.append("fullName", values.fullName);
        formData.append("profilePicture", values.profilePicture);

        const response = await httpAPI.post(
          `${process.env.REACT_APP_BACKEND_URL}/admin/updateProfile`,
          formData
        );

        if (response.data) {
          const userObject = response.data.response;
          setUser(userObject);
          localStorage.setItem("user", JSON.stringify(userObject));
          toast.success("Updated Successfully");
          setIsSubmitting(false);
          navigate("/dashboard/home");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  });

  const handleImageChange = (e) => {
    const uploadedImage = e.target.files[0];
    if (uploadedImage) {
      formik.setFieldValue("profilePicture", uploadedImage);
      setSelectedImage(URL.createObjectURL(uploadedImage));
    } else {
      setSelectedImage(null);
    }
  };

  const onCancel = () => {
    navigate("/dashboard/home");
  };

  return (
    <>
      <Box className="update-profile" sx={{ boxSizing: "border-box" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            width: '100%',
            padding: "1rem",
            background: "#eeeeee",
            borderRadius: "5px",
            marginBottom: "2rem",
            boxSizing: "border-box",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "flex-start", gap: "1rem" }}>
            {/* <span style={{ cursor: 'pointer' }} onClick={handleGoBack}>
            <TbSquareRoundedArrowLeftFilled style={{ fontSize: '30px', color: '#212529' }} />
          </span> */}
            <h2 style={{ margin: "0" }}>Update Profile</h2>
          </Box>
        </Box>
        <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
          <Box
            sx={{
              margin: "auto",
              width: { xs: "auto", sm: "auto", md: "auto", lg: "50%" },
              padding: {
                xs:'0',
                sm:'1rem',
                md:'2rem',

              },
            }}
          >
            <Box>
              <Box
                sx={{
                  width: "200px",
                  height: "200px",
                  margin: "auto",
                  border: "1px solid gray",
                  maxHeight: "200px",
                  borderRadius: "5px",
                }}
              >
                <img
                  src={
                    selectedImage ||
                    formik.values.profilePicture ||
                    profilePicture
                  }
                  alt="Selected"
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "5px",
                    objectFit: "cover",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "2rem 0",
                }}
              >
                <input
                  accept=".png, .jpg, .jpeg"
                  style={{ display: "none" }}
                  id="profilePicture"
                  name="profilePicture"
                  type="file"
                  onChange={handleImageChange}
                  onBlur={formik.handleBlur}
                />
                <label htmlFor="profilePicture">
                  <Button variant="contained" component="span">
                    Upload Profile Picture
                  </Button>
                </label>
                {formik.touched.profilePicture &&
                  formik.errors.profilePicture && (
                    <Typography color="error" mt={1}>
                      {formik.errors.profilePicture}
                    </Typography>
                  )}
              </Box>
            </Box>

            <Box>
              <Box sx={{ marginBottom: "15px" }}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  variant="outlined"
                  value={email}
                  InputProps={{ readOnly: true }}
                  mb={'1rem'}
                />
              </Box>
              <TextField
                fullWidth
                id="fullName"
                name="fullName"
                label="Full Name"
                variant="outlined"
                value={(formik.values.fullName || "").trim()}
                onChange={(e) => {
                  const trimmedValue = e.target.value.trim();
                  formik.handleChange(e);
                  formik.setFieldValue("fullName", trimmedValue);
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                helperText={formik.touched.fullName && formik.errors.fullName}
                mb={2}
              />



              <Box mt={2} display="flex" justifyContent="space-between">
                <Button type="submit" variant="contained" color="primary">
                  {isSubmitting ? "Saving..." : "Save"}
                </Button>
                <Button type="button" variant="outlined" onClick={onCancel}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default UpdateProfile;
