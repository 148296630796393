import { AppBar, Box, FormControl, IconButton, InputAdornment, TextField, Toolbar } from '@mui/material'
import React from 'react'

import { MdMenu } from "react-icons/md";
import { LuSearch } from "react-icons/lu";

const drawerWidth = 240;

const Topbar = ({ handleDrawerToggle }) => {
    return (
        <AppBar position="fixed" sx={{ backgroundColor: '#212529', borderBottom: '0.5px solid #e9e9e9', boxShadow: 'none', flexGrow: 1, p: 1, width: { md: `calc(100% - ${drawerWidth}px)` } }}>
            <Toolbar sx={{ backgroundColor: 'transparent', minHeight: '40px', padding: '0' }}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ ml: 2, fontSize: '20px', padding: '0', display: { md: 'none' } }}
                >
                    <MdMenu style={{ color: '#fff' }} />
                </IconButton>

            </Toolbar>

        </AppBar>
    )
}

export default Topbar
