import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { httpAPI } from "../../AxiosApi.js";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  TablePagination,
  TableSortLabel,
  Tabs,
  Tab,
  Box,
  Skeleton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { FaArrowUp } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import { MdVisibility } from "react-icons/md";
import moment from 'moment';
import { useAuth } from "../Utils/AuthContext.js";



export default function Student() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("fullName");
  const [order, setOrder] = useState("asc");
  const [activeTab, setActiveTab] = useState("all");
  const [isStatusChanging, setStatusChanging] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [userIdToToggle, setUserIdToToggle] = useState(null);
  const { logout } = useAuth();
  const navigate = useNavigate();



  const fetchData = async () => {
    try {
      const response = await httpAPI.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/studentDetails`
      );
      if (response.data && response.data.response) {
        setData(response.data.response);
      } else {
        console.error("Invalid data structure in the response:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response) {
        if (error.response.status === 401) {
          toast.error(error.response.data.message);
          logout();
          navigate("/");
        } else {
          toast.error("An error occurred:", error.message);
        }
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const toggleStatus = async (userId, isActive) => {
    setUserIdToToggle(userId);
    setConfirmDialogOpen(true);
  };
  const handleConfirmToggle = async (userId) => {
    setStatusChanging(true);
    setConfirmDialogOpen(false);
    try {
      const response = await httpAPI.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/checkIsActive/${userId}`
      );
      if (response.status == 200) {
        toast.success("Status changed successfully");
        setStatusChanging(false);
        fetchData();
      } else if (response.status === 400) {
        toast.success("Session Expired")
        logout();
      }

    } catch (error) {
      console.error("Error toggling isActive:", error);
      setStatusChanging(false);
    }
  };
  const handleCancelToggle = () => {
    setConfirmDialogOpen(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangeTab = (event, newValue) => {
    console.log(newValue)
    setActiveTab(newValue);
  };
  const filterByValue = (object, searchValue) => {
    return Object.values(object).some((value) => {
      if (typeof value === 'object' && value !== null) {
        return filterByValue(value, searchValue);
      }
      return value.toString().toLowerCase().includes(searchValue.toLowerCase());
    });
  };

  const filteredData = data.filter((student) => filterByValue(student, globalFilter));

  const sortedData = stableSort(filteredData, getComparator(order, orderBy));

  let displayedStudents;
  if (activeTab === "active") {
    displayedStudents = sortedData.filter((student) => student.isActive);
  } else if (activeTab === "inactive") {
    displayedStudents = sortedData.filter((student) => !student.isActive);
  } else {
    displayedStudents = sortedData;
  }

  const activeStudentsCount = data.filter((student) => student.isActive).length;
  const inactiveStudentsCount = data.filter((student) => !student.isActive).length;

  return (
    <div className="student" >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isStatusChanging}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div style={{ width: "100%" }}>
        <h1 style={{ textAlign: 'left' }}>Student Listing</h1>
      </div>

      <Box style={{ width: "100%", borderRadius: '10px' }}>

        <Box
          sx={{
            // borderBottom: 1,
            borderColor: "divider",
            // mb: "0.5rem", 
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: '10px 15px',
            boxSizing: 'border-box'
          }}
        >

          <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            variant="fullWidth"
            aria-label="Active and Inactive Tabs"
            sx={{ background: "#fff", padding: "5px", borderRadius: "5px", width: { xs: '100%', sm: '100%', md: '100%', lg: '50%', xl: '50%' }, }}
          >
            <Tab
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                  <span style={{ paddingRight: '10px', fontWeight: "bold" }}>

                    All{" "}
                  </span>
                  <span style={{ backgroundColor: "#212B36", color: "#fff", padding: "5px 10px", borderRadius: "5px" }}>
                    {data.length}
                  </span>
                </Box>
              }
              value="all"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#eaeaea",
                  color: "#000",
                  borderRadius: "5px",
                },
              }}
            />
            <Tab
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                  <span style={{ paddingRight: '10px', fontWeight: "bold" }}>

                    Active{" "}
                  </span>
                  <span style={{ backgroundColor: "#22C55E29", color: "#118D57", padding: "5px 10px", borderRadius: "5px", fontWeight: 900 }}>
                    {activeStudentsCount}
                  </span>
                </Box>
              }
              // label={`Active (${activeStudentsCount})`}
              value="active"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#eaeaea",
                  color: "#000",
                  borderRadius: "5px",
                },
              }}
            />
            <Tab
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                  <span style={{ paddingRight: '10px', fontWeight: "bold" }}>

                    Inactive{" "}
                  </span>
                  <span style={{ backgroundColor: "#FF563029", color: "#B71D18", padding: "5px 10px", borderRadius: "5px", fontWeight: 900 }}>
                    {inactiveStudentsCount}
                  </span>
                </Box>
              }
              // label={`Inactive (${inactiveStudentsCount})`}
              value="inactive"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#eaeaea",
                  color: "#000",
                  borderRadius: "5px",
                },
              }}
            />

          </Tabs>






          {/* <div className="btnHeader"> */}
          <Box className="search" style={{ display: 'flex', justifyContent: 'flex-end' }}>

            <TextField
              value={globalFilter}
              // sx={{width:'100%' }}
              type="search"
              placeholder="Search by Name"
              onChange={(e) => setGlobalFilter(e.target.value)}
              label="Search"
            />
          </Box>
          {/* </div> */}

        </Box>

        <TableContainer >
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell>Image</TableCell> */}
                <TableCell >
                  <TableSortLabel
                    style={{ paddingLeft: '10px' }}
                    active={orderBy === "fullName"}
                    direction={orderBy === "fullName" ? order : "asc"}
                    onClick={() => handleSortRequest("fullName")}
                    SortIconComponent={FaArrowUp}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                {/* <TableCell>
                <TableSortLabel
                  active={orderBy === "email"}
                  direction={orderBy === "email" ? order : "asc"}
                  onClick={() => handleSortRequest("email")}
                  SortIconComponent={FaArrowUp}
                >
                  Email
                </TableSortLabel>
              </TableCell> */}
                <TableCell >
                  <TableSortLabel
                    active={orderBy === "phone.phoneNumber"}
                    direction={orderBy === "phone.phoneNumber" ? order : "asc"}
                    onClick={() => handleSortRequest("phone.phoneNumber")}
                    SortIconComponent={FaArrowUp}
                  >
                    Phone
                  </TableSortLabel>
                </TableCell>
                <TableCell >
                  <TableSortLabel
                    active={orderBy === "studentId"}
                    direction={orderBy === "studentId" ? order : "asc"}
                    onClick={() => handleSortRequest("studentId")}
                    SortIconComponent={FaArrowUp}
                  >
                    Student ID
                  </TableSortLabel>
                </TableCell>
                {/* <TableCell>
                <TableSortLabel
                  active={orderBy === "dateOfBirth"}
                  direction={orderBy === "dateOfBirth" ? order : "asc"}
                  onClick={() => handleSortRequest("dateOfBirth")}
                  SortIconComponent={FaArrowUp}
                >
                  Date of Birth
                </TableSortLabel>
              </TableCell> */}
                <TableCell >
                  <TableSortLabel
                    active={orderBy === "instituteName"}
                    direction={orderBy === "instituteName" ? order : "asc"}
                    onClick={() => handleSortRequest("instituteName")}
                    SortIconComponent={FaArrowUp}
                  >
                    Institute Name
                  </TableSortLabel>
                </TableCell>

                <TableCell >Active/ Inactive</TableCell>
                <TableCell >Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                Array.from({ length: 8 }).map((_, index) => (
                  <TableRow key={index}>
                    {[...Array(6)].map((_, i) => (
                      <TableCell key={i}>
                        <Skeleton animation="wave" width={"100%"} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : displayedStudents.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={9} align="center" sx={{ textAlign: 'center !important' }}>
                    No data found
                  </TableCell>
                </TableRow>
              ) : (
                displayedStudents
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row._id}  >
                      <TableCell style={{ fontSize: "16px" }} >
                        <NavLink style={{ display: 'flex', gap: "10px", color: 'black', textDecoration: 'none' }} to={`/dashboard/viewStudent/${row._id}`}>
                          <img
                            src={(row.profilePicture && !row.profilePicture.endsWith('/null'))
                              ? row.profilePicture 
                              : "/images/dummyImage.png"}
                            alt="Student-img"
                            // style={{ width: 30 }}
                            style={{ borderRadius: '50%', width: 40, height: 40 }}
                          // onError={(e) => (e.target.src = "dummy-image-url")}
                          />
                          <span>
                            <span style={{ fontWeight: 600 }}>
                              {row.fullName.length > 20
                                ? row.fullName.substring(0, 20) + "..."
                                : row.fullName}
                            </span>
                            <br />
                            {row.email.length > 20 ? row.email.substring(0, 20) + "..." : row.email}
                          </span>
                        </NavLink>
                      </TableCell>

                      <TableCell style={{ fontSize: "16px" }}>{`${row.phone.countryCode}-${row.phone.phoneNumber}`}</TableCell>
                      <TableCell>
                        {row.studentId ? row.studentId : "NA"}
                      </TableCell  >
                      {/* <TableCell>
                      {row.dateOfBirth && moment(row.dateOfBirth).format('MMM DD, YYYY')}

                    </TableCell> */}
                      <TableCell style={{ fontSize: "16px" }}>
                        {row?.instituteName.length
                          ? row?.instituteName.substring(0, 30) + "..."
                          : "NA"}
                      </TableCell>
                      <TableCell style={{ fontSize: "16px" }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={row.isActive}
                              onChange={() => toggleStatus(row._id, row.isActive)}
                            />
                          }
                        />
                        {/* <Button variant="outlined">
                        {row.isActive ? 'Active' : 'Inactive'}
                      </Button>    */}
                      </TableCell >
                      <TableCell style={{ fontSize: "16px" }}>
                        <NavLink to={`/dashboard/viewStudent/${row._id}`}>
                          <MdVisibility
                            style={{ fontSize: "20px", color: "#000" }}
                          />
                        </NavLink>
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>



      {!loading && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={displayedStudents.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <Dialog
        open={confirmDialogOpen}
        onClose={handleCancelToggle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Status Change"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to change the status?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelToggle} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleConfirmToggle(userIdToToggle)} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />

    </div>
  );
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}