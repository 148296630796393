import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { httpAPI } from "../../AxiosApi.js";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  TablePagination,
  TableSortLabel,
  Box,
  Skeleton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { FaArrowUp } from "react-icons/fa";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { MdVisibility } from "react-icons/md";
import moment from 'moment';
import { useAuth } from "../Utils/AuthContext.js";

const Transactions = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("patronID.fullName");
  const [order, setOrder] = useState("asc");
  const [activeTab, setActiveTab] = useState("active");
  const [isStatusChanging, setStatusChanging] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [userIdToToggle, setUserIdToToggle] = useState(null);
  const { logout } = useAuth();
  const navigate = useNavigate();



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await httpAPI.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/transactions`
        );
        if (response.data && response.data.response) {
          setData(response.data.response);
        } else {
          console.error("Invalid data structure in the response:", response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        if (error.response) {
          if (error.response.status === 401) {
            toast.error(error.response.data.message);
            logout();
            navigate("/");
          } else {
            toast.error("An error occurred:", error.message);
          }
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [logout, navigate]);




  const handleConfirmToggle = async (userId) => {
    setStatusChanging(true);
    setConfirmDialogOpen(false);
    try {
      const response = await httpAPI.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/checkIsActive/${userId}`
      );
      if (response.status == 200) {
        toast.success("Status changed successfully");
        setStatusChanging(false);
        // fetchData();
      } else if (response.status === 400) {
        toast.success("Session Expired")
        logout();

      }

    } catch (error) {
      console.error("Error toggling isActive:", error);
      setStatusChanging(false);
    }
  };
  const handleCancelToggle = () => {
    setConfirmDialogOpen(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortRequest = (sortBy) => {
    setOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
    // setOrderBy(sortBy)
    const sortedList = [...data].sort((a, b) => {
      if (order === 'asc') {
        return a[sortBy] > b[sortBy] ? 1 : -1
      } else {
        return a[sortBy] < b[sortBy] ? 1 : -1
      }
    })
    // setDonorList(sortedList)
    setData(sortedList);
    setOrderBy(sortBy);
  }


  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };
  const filterByValue = (object, searchValue) => {
    return Object.values(object).some((value) => {
      if (typeof value === 'object' && value !== null) {
        return filterByValue(value, searchValue);
      }
      return value.toString().toLowerCase().includes(searchValue.toLowerCase());
    });
  };

  const filteredData = data.filter((student) => filterByValue(student, globalFilter));

  const sortedData = stableSort(filteredData, getComparator(order, orderBy));
  // const activeStudents = sortedData.filter((student) => student.isActive);
  // const inactiveStudents = sortedData.filter((student) => !student.isActive);
  // const displayedStudents =
  //   activeTab === "active" ? activeStudents : inactiveStudents;


  return (
    <div className="student">
      <div style={{ width: '100%' }}>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isStatusChanging}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", }}>
        <h2 >Transaction Listing</h2>

        {/* <Box className="search"> */}
        <TextField
          value={globalFilter}
          type="search"
          onChange={(e) => setGlobalFilter(e.target.value)}
          label="Search"
          sx={{ width: '30%' }}
        />
        {/* </Box> */}
      </Box>


      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          mb: "0.5rem",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
        }}
      >

      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {/* <TableCell>Image</TableCell> */}
              <TableCell  >
                <TableSortLabel
                  active={orderBy === "patronID.fullName"}
                  direction={orderBy === "patronID.fullName" ? order : "asc"}
                  onClick={() => handleSortRequest("patronID.fullName")}
                  SortIconComponent={FaArrowUp}
                >
                  Patron Name
                </TableSortLabel>
              </TableCell>
              {/* <TableCell>
                <TableSortLabel
                  active={orderBy === "patronID.email"}
                  direction={orderBy === "patronID.email" ? order : "asc"}
                  onClick={() => handleSortRequest("patronID.email")}
                  SortIconComponent={FaArrowUp}
                >
                  Patron Email
                </TableSortLabel>
              </TableCell> */}
              <TableCell  >
                <TableSortLabel
                  active={orderBy === "amount"}
                  direction={orderBy === "amount" ? order : "asc"}
                  onClick={() => handleSortRequest("amount")}
                  SortIconComponent={FaArrowUp}
                >
                  Amount
                </TableSortLabel>
              </TableCell  >
              <TableCell >
                <TableSortLabel
                  active={orderBy === "phone.phoneNumber"}
                  direction={orderBy === "phone.phoneNumber" ? order : "asc"}
                  onClick={() => handleSortRequest("phone.phoneNumber")}
                  SortIconComponent={FaArrowUp}
                >
                  Scholarship
                </TableSortLabel>
              </TableCell>
              <TableCell >
                <TableSortLabel
                  active={orderBy === "scholarshipID?.userId?.fullName"}
                  direction={orderBy === "scholarshipID?.userId?.fullName" ? order : "asc"}
                  onClick={() => handleSortRequest("scholarshipID?.userId?.fullName")}
                  SortIconComponent={FaArrowUp}
                >
                  Reciept Name
                </TableSortLabel>
              </TableCell>
              <TableCell >
                <TableSortLabel
                  active={orderBy === "scholarshipID?.userId?.email"}
                  direction={orderBy === "scholarshipID?.userId?.email" ? order : "asc"}
                  onClick={() => handleSortRequest("scholarshipID?.userId?.email")}
                  SortIconComponent={FaArrowUp}
                >
                  Reciept Email
                </TableSortLabel>
              </TableCell>
              <TableCell >
                <TableSortLabel
                  active={orderBy === "createdAt"}
                  direction={orderBy === "createdAt" ? order : "asc"}
                  onClick={() => handleSortRequest("createdAt")}
                  SortIconComponent={FaArrowUp}
                >
                  Date of Transaction
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              Array.from({ length: 8 }).map((_, index) => (
                <TableRow key={index}>
                  {[...Array(6)].map((_, i) => (
                    <TableCell key={i}>
                      <Skeleton animation="wave" width={"100%"} />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={9} align="center" sx={{ textAlign: 'center !important' }}>
                  No data found
                </TableCell>
              </TableRow>
            ) : (
              sortedData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>
                  
                      <NavLink style={{ display: 'flex', gap: "10px", color: 'black', textDecoration: 'none' }} to={`/dashboard/viewPatron/${row.patronID._id}`}>

                        <img

                          src={row?.userID?.profilePicture && !row?.userID?.profilePicture?.endsWith('/null')
                            ? `${process.env.REACT_APP_BACKEND_URL}/uploads/${row.userID?.profilePicture}`
                            : "/images/dummyImage.png"} alt="Student-img"
                          // style={{ width: 30 }}
                          style={{ borderRadius: '50%', width: 40, height: 40 }}
                        />

                        <span>
                          <span style={{ fontWeight: 600 }}>

                            {row?.patronID?.fullName?.length > 20
                              ? row?.patronID?.fullName?.substring(0, 20) + "..."
                              : row?.patronID?.fullName}
                          </span>

                          <br />
                          {row.patronID.email.length > 20 ? row.patronID.email.substring(0, 20) + "..." : row.email}

                        </span>

                      </NavLink>



                    </TableCell>
                    {/* <TableCell>{`${row.phone.countryCode}-${row.phone.phoneNumber}`}</TableCell> */}
                    <TableCell style={{ fontSize: "16px" }}  >
                      ${row.amount ? row.amount : "NA"}
                    </TableCell>
                    <TableCell style={{ fontSize: "16px" }} >
                      {row?.scholarshipID?.title ? row?.scholarshipID?.title : "NA"}

                    </TableCell>
                    <TableCell style={{ fontSize: '16px', }} >


                      {row?.scholarshipID?.userId?.role === 'student' && (
                        <NavLink style={{ display: 'flex', gap: "10px", color: 'black', textDecoration: 'none' }} to={`/dashboard/viewStudent/${row?.scholarshipID?.userId?._id}`}>
                          {row?.scholarshipID?.userId?.fullName}
                        </NavLink>
                      )}
                      {row?.scholarshipID?.userId?.role !== 'student' && (
                        <NavLink style={{ display: 'flex', gap: "10px", color: 'black', textDecoration: 'none' }} to={`/dashboard/viewInstitute/${row?.scholarshipID?.userId?._id}`}>
                          {row?.scholarshipID?.userId?.fullName}
                        </NavLink>
                      )}
                    </TableCell>

                    <TableCell style={{ fontSize: "16px" }}>
                      {row?.scholarshipID?.userId?.email}
                    </TableCell>
                    <TableCell style={{ fontSize: "16px" }}>
                      {row.createdAt && moment(row?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                    </TableCell>

                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <Dialog
        open={confirmDialogOpen}
        onClose={handleCancelToggle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Status Change"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to change the status?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelToggle} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleConfirmToggle(userIdToToggle)} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />

    </div>
  )
}

export default Transactions



function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}